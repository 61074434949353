.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
}

@media (min-width: 768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: "";
    display: block;
    border-top: 0.25rem dotted #64ac3458;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    /* top: 0.3125rem; */
    top: 1.2rem;
  }
  .timeline-steps .timeline-step:not(:first-child):before {
    content: "";
    display: block;
    border-top: 0.25rem dotted #64ac3458;
    width: 3.8125rem;
    position: absolute;
    right: 7.5rem;
    /* top: 0.3125rem; */
    top: 1.2rem;
  }
}

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 50%;
  height: 2.4rem;
  width: 2.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6;
  /* background-color: #64ac3429; */
}
/* 
.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #3b82f6;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: 0.5;
} */
.timeline-step p {
  color: gray;
  font-size: 0.9rem;
  margin-top: 0.5rem !important;
}

.selected-step .inner-circle {
  background-color: #64ac34 !important;
  color: #fff !important;
}

.selected-step p {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.799);
}

.inner-circle span {
  color: #fff;
  /* color: rgba(0, 0, 0, 0.799); */
  font-size: 1.2rem;
}

.selected-step .inner-circle span {
  color: white;
}
