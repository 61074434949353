.dashboard_card {
  border-radius: 8px;
  height: 160px;
  width: 200px;

  display: grid;
  grid-template-columns: 10fr 4fr;
  box-shadow: 2px 2px 25px rgba(163, 163, 163, 0.144);
  padding: 1.5rem;
  background-color: #fff;
  transition: all 0.3s;
}

.dashboard_card:hover {
  transform: translateY(-5px);
  box-shadow: 2px 2px 5px rgba(163, 163, 163, 0.144);
}
.dashboard_card h1 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
}
.dashboard_card small {
  font-weight: 600;
  color: gray;
  font-size: 0.8rem;
}
.dashboard_card h5 {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.4rem;
}
.dashboard_card span {
  background-color: #1163e7;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  padding: 6px 8px 7px 8px;
  font-size: 25px;
}

.background {
  background-color: white;
  margin-top: 12px;
  margin-left: 1rem !important;
  margin-right: 5px;
  border-radius: 5px;
  padding-bottom: 30px;
}
.background h3 {
  font-size: 1.2rem;
}
.balance {
  display: grid;
  padding-left: 1.7rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
  padding-right: 4rem;

  padding-top: 2rem;
  padding-bottom: 2rem;
}

.balance h4,
button {
  font-weight: 600 !important;
}
.tableData small {
  font-size: 0.9rem;
}
.tableData {
  margin-top: 1rem;
}

.tableData thead th {
  border-top: 1px solid rgba(215, 215, 215, 0.796);
  border-bottom: 1px solid rgba(215, 215, 215, 0.796);
  padding-top: 13px;
  padding-bottom: 13px;
}
.tableData table td {
  padding-top: 25px !important;
}
hr {
  opacity: 0.1;
}

.hide {
  display: none;
}

.header p {
  font-size: 0.9rem;
  font-weight: 600;
}

.code {
  color: #1163e7;
  cursor: pointer;
}

.unpaid {
  background-color: #ef486a;
  color: #fff;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 0.9rem;
  margin-right: 0.2rem;
}

.paid {
  padding: 2px 4px;
  border-radius: 3px;
  background-color: #1163e7;
  color: #fff;
  font-size: 0.9rem;
  margin-right: 0.2rem;
}

@media only screen and (min-width: 768px) {
  .dashboard_card {
    border-radius: 8px;
    height: 160px;
    width: 300px;

    display: grid;
    grid-template-columns: 10fr 4fr;
    box-shadow: 2px 2px 25px rgba(163, 163, 163, 0.144);
    padding: 1.5rem;
    background-color: #fff;
    transition: all 0.3s;
  }
  .background {
    background-color: white;
    margin-top: 12px !important;
    margin-left: 10px;
    border-radius: 5px;
  }
  .balance {
    display: flex;
    justify-content: space-between;
    padding-left: 1.7rem;
    margin-top: -1rem;
    margin-bottom: 2rem;

    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1024px) {
  .hide {
    display: block;
  }
}
