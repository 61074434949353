body {
  font-family: "Work Sans", "Source Sans Pro", "Roboto", sans-serif;
  font-size: 14px;
  position: relative;
}
* {
  margin: 0;
  padding: 0;
}
dl,
ol,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
::placeholder {
	color: #e1e1e1;
	opacity: 1; /* Firefox */
  	font-weight: 400;
  	font-size: 13px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	  color: #e1e1e1;
    font-size: 13px;
    font-weight: 400;
    font-size: 13px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	color: #e1e1e1;
    font-weight: 400;
    font-size: 13px;
  }
a{
  color: #000;
  text-decoration: none !important;
}
.btn a{
  color: #fff !important;
}
.w-30{
  width: 30%;
}
.font_500{
  font-weight: 500;
}
.logo a{
	color: #000;
	font-weight: 700;
	font-size: 20px;
	text-decoration: none;
}
.langSelect{
  width: fit-content;
	font-size: 13px;
	border: none;
	padding: 0;
}
.breadcrumb-item.active{
	color: #fff !important;
}
.css-tlfecz-indicatorContainer{
  color:#000
}
.header .main-header .search-wrap .search-select {
  border: 0;
  font-size: 14px;
  border-left: 1px solid #d5d5d5;
  padding: 8px 7px;
}
.form-control:focus {
  box-shadow: none;
  border-color: #d7d7d7;
}
.header-menu a {
  text-decoration: none !important;
  color: #333333 !important;
}
.header-menu a:hover {
  color: #2e74e8 !important;
}

.mobile-menu-open {
  left: 0 !important;
  width: 100% !important;
}
.primary-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: #0c6adf;
  color: #ffffff;
  width: auto;
  transition: all 0.5s linear;
}

.btn-primary {
  color: #ffffff !important;
}
.primary-button:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #1164cb;
}

.search-wrap input {
  height: 42px;
  padding-right: 10px !important;
}

/* .search-wrap select {
  background-color: #fff;
  outline: none;
  border: 1px solid rgba(128, 128, 128, 0.319);
  width: 50px !important;
  cursor: pointer;
  transition: all 150ms ease;
} */
/* .search-wrap select:focus {
  box-shadow: none;
} */

.wrapper {
  background-color: #fff;
}

.show {
  display: block !important;
}

/* scrollbar design */
::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar {
  width: 2px;
}

/* ./scrollbar design */
/* Animations */
@keyframes slide-in {
  from {
    opacity: 0;
    right: -50%;
  }
}

.radio-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.radio-item span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 5px solid #ffffff;
  outline: 1px solid #d7d7d7;
  margin-left: 10px;
  transition: all 0.3s;
}
.radio-item span:hover {
  cursor: pointer;
  outline: 1px solid #2e74e8;
}
.radio-item .color {
  height: 26px;
  width: 26px;
}
.radio-item .size {
  padding: 0px 5px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
}

body.show:after {
  background-color: #222;
  opacity: 0.5;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.dropdown-menu {
  min-width: max-content;
  padding: 0;
  transform: none !important;
  top: 100% !important;
  overflow: hidden;
}

.fix-header {
  margin-top:50px
}

.topbar {
  background: #fff;
  padding: 5px 0;
}
.topbar .left-menu,
.topbar .right-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.topbar .left-menu ul,
.topbar .right-menu ul {
  list-style: none;
  margin-bottom: 0 !important;
}
.topbar .left-menu ul li,
.topbar .right-menu ul li {
  list-style: none;
  cursor: pointer;
}
.topbar .left-menu ul li a,
.topbar .right-menu ul li a {
  display: inline-block;
  padding: 8px 12px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 400;
  // color: #000;
}
.topbar ul {
  padding-left: 2%;
}
.topbar .left-menu ul li a.first-child,
.topbar .right-menu ul li a.first-child {
  padding-right: 0;
}
.topbar .left-menu ul li a.last-child,
.topbar .right-menu ul li a.last-child {
  padding-right: 0;
}
.topbar .left-menu ul li a span,
.topbar .right-menu ul li a span {
  margin-left: 8px;
}

.topbar .left-menu .css-13cymwt-control,
.topbar .left-menu .css-t3ipsp-control  {
  background-color: transparent;
  font-size: 13px;
  border-color: transparent !important;
  max-width: 85px;
}
.topbar .left-menu .css-1u9des2-indicatorSeparator {
  width: 0;
}

/* main header start */
.header .main-header {
  background-color: #d9e8fb;
  padding: 30px 0 30px 0;
}

.main-header .container {
  position: relative;
}

.headerMiniCart {
  position: absolute;
  top: 30px;
  right: 12px;

  z-index: -10;
  height: 0;
  opacity: 0;
  transition: all 0.3s;
  transform: translateY(40px);
}

.headerMiniCartIcon:hover .headerMiniCart {
  opacity: 1;
  transform: translateY(1px);
  z-index: 999;
}
.header .main-header .logo {
  max-width: 160px;
  height: auto;
}
.header .main-header .logo a {
  display: inline-block;
}
.header .main-header .logo img {
  width: 100%;
  height: auto;
}
.search-wrap {
  position: relative;
}

.search-template {
  z-index: 100;
  background-color: #fff;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.single_search_prod {
  display: grid;
  grid-template-columns: 100px auto;
  gap: 11px;
  align-items: center;
  border: 0.5px solid #ddd;
  padding: 6px;
}
p.ssp-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  min-height: 55px;
}
p.ssp-price {
  font-size: 13px;
}
.header {
  .main-header .search-wrap .input-group { 
    z-index: 9;
    .btn.search-button {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      font-family: 'Work Sans', sans-serif;
      font-weight: 400 !important;
    }
    .css-1u9des2-indicatorSeparator {
      display: none;  
    }
    .css-16wwvxr-control {
      min-height: 42px;
      height: 42px !important;
      &:hover {
        border-color: transparent !important;
      }
    }
  } 
}
.header .main-header .search-wrap .search-text {
  flex: 2;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.header .main-header .search-wrap .search-text:before {
  content: "";
  height: 22px;
  width: 13px;
  background: blue;
  z-index: 14;
  position: absolute;
  left: 9px;
  top: 0;
}
.header .main-header .search-wrap .search-select {
  border-right: 0;
  position: relative;
}
.header .main-header .search-wrap .search-select:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  top: 0;
}
.header .main-header .search-wrap .search-button {
  background-color: #2e74e8;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 25px;
  color: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
}
.header .main-header .menu-icons {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 40px;
  padding-right: 0;
  padding-top: 8px;
}
.header .main-header .menu-icons ul {
  list-style: none;
}
.header .main-header .menu-icons ul li {
  list-style: none;
  cursor: pointer;
}
.menu-icons a {
  display: inline-block;
  padding: 0 8px;
  text-decoration: none;
  font-size: 18px;

  color: #333333;
  position: relative;
  margin-right: 18px;
  font-size: 1.2rem;
  font-weight: 100 !important;
}
.header .main-header .menu-icons ul li a.first-child {
  padding-right: 0;
}
.header .main-header .menu-icons ul li a.last-child {
  margin-right: 0;
}
.header .main-header .menu-icons ul li a span {
  margin-left: 8px;
  background-color: #2e74e8;
  color: #fff;
  position: absolute;
  left: 12px;
  bottom: 13px;
  padding: 0px 6px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 100;
}
.header .main-header .menu-icons ul .login a {
  font-size: 1.2rem;
}
.header .main-header .menu-icons ul .login span {
  margin-left: 8px;
  background-color: inherit;
  color: black;
  font-size: 1rem;
  font-weight: 500;
  position: absolute;
  left: 20px;
  bottom: 1px;
}

.header .header-menu {
  border-bottom: 1px solid #d7d7d7;
}
.header .header-menu .shop-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 15px;
}

.header .header-menu .shop-menu ul {
  list-style: none;
}
.header .header-menu .shop-menu ul li {
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.header .header-menu .shop-menu ul li a {
  display: inline-block;
  padding: 0 12px;
  text-decoration: none;
  font-weight: 500 !important;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
}
.header .header-menu .shop-menu ul li a.first-child {
  padding-right: 0;
}
.header .header-menu .shop-menu ul li a.last-child {
  padding-right: 0;
}
.header .header-menu .shop-menu ul li a span {
  margin-left: 8px;
}
.head-cat-select__control{
	height: 42px !important;
}
/* responsive style */

@media (max-width: 768px) {
	.head-cat-select__control{
		width: 150px !important;
	}
}


@media (max-width: 767px) {
  .header .main-header .menu-icons {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 40px;
    padding-right: 0;
    margin-right: 0;
    margin-top: 1px;
  }
  .login {
    display: none;
  }
  .header .main-header .menu-icons ul li a {
    display: inline-block;
    padding: 0 8px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    position: relative;
    margin-right: 10px;
    font-size: 1.1rem;
    font-weight: 100 !important;
  }
  .logo img {
    width: 60% !important;
  }
  .topbar .left-menu,
  .topbar .right-menu {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .topbar .left-menu ul,
  .topbar .right-menu ul {
    list-style: none;
  }
  .topbar .left-menu ul li,
  .topbar .right-menu ul li {
    list-style: none;
    cursor: pointer;
  }
  .topbar .left-menu ul li a,
  .topbar .right-menu ul li a {
    display: inline-block;
    padding: 5px 8px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
  .topbar .left-menu ul li a.first-child,
  .topbar .right-menu ul li a.first-child {
    padding-right: 0;
  }
  .topbar .left-menu ul li a.last-child,
  .topbar .right-menu ul li a.last-child {
    padding-right: 0;
  }
  .topbar .left-menu ul li a span,
  .topbar .right-menu ul li a span {
    margin-left: 8px;
  }

  .header .header-menu {
    border-bottom: 1px solid #d7d7d7;
    position: relative;
  }
  .header .header-menu .shop-menu {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .header .header-menu .shop-menu ul {
    list-style: none;
  }
  .header .header-menu .shop-menu ul li {
    list-style: none;
    cursor: pointer;
  }
  .header .header-menu .shop-menu ul li a {
    display: inline-block;
    padding: 0 12px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
  }
  .header .header-menu .shop-menu ul li a.first-child {
    padding-right: 0;
  }
  .header .header-menu .shop-menu ul li a.last-child {
    padding-right: 0;
  }
  .header .header-menu .shop-menu ul li a span {
    margin-left: 8px;
  }

  .header .header-menu .shop-menu .mobile-nav-open {
    font-size: 20px;
    cursor: pointer;
    padding: 10px;
    // right: 10px;
    // top: 18px;
    // position: absolute;

  }
  .header .header-menu .shop-menu .side-navigation {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 0;
    left: -100px;
    background-color: #060606fa;
    overflow-x: hidden;
    transition: 0.2s;
    padding-top: 60px;
  }

  .header .header-menu .shop-menu .side-navigation a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #fff !important;

    display: block;
    transition: 0.3s;
  }
  .header .header-menu .shop-menu .side-navigation a:hover {
    color: #f1f1f1;
  }
  .header .header-menu .shop-menu .side-navigation .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: #fff;
  }
}

#react-select-4-listbox {
  z-index: 100;
}
.header-sml-btn {
  position: relative;
  margin-left: 25px;
}

.header-sml-btn span {
  position: absolute;
  background-color: #2d73e7;
  color: #fff;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 11px;
  top: -5px;
  right: -9px;
}
.header-sml-btn svg {
  font-size: 22px;
}


.btn-outline-success{
  font-family: sans-serif;
}
