.header_title {
  font-size: 1.5rem;
  font-weight: 500;
}

.dashboard_card {
  border-radius: 8px;
  height: 160px;

  margin-bottom: 2rem;

  display: grid;
  grid-template-columns: 10fr 4fr;

  padding: 1.5rem;
  background-color: #fff;
  transition: all 0.3s;
}

.dashboard_card:hover {
  transform: translateY(-5px);
  box-shadow: 2px 2px 5px rgba(163, 163, 163, 0.144);
}

.dashboard_card h1 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
}
.dashboard_card small {
  font-weight: 600;
  color: gray;
  font-size: 0.8rem;
}
.dashboard_card h5 {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.4rem;
}
.dashboard_card span {
  background-color: #1163e7;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  padding: 6px 8px 7px 8px;
  font-size: 25px;
}

.tables {
  margin-top: 1rem;
  background-color: #f2f3f8 !important;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.table {
  width: 100%;
  overflow: scroll;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 1rem 0 1rem;
  width: 100%;
}
.table thead {
  background-color: rgba(200, 200, 200, 0.358);
}
.tableHeader {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding: 12px 0 8px 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.256);
}
@media only screen and (min-width: 768px) {
  .dashboard_card {
    margin-bottom: 0;
  }

  .tables {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}
