.background {
  background-color: white;
  border-radius: 5px;
  padding-bottom: 2%;
}

hr {
  opacity: 0.1;
}

.hide {
  display: none;
}
.header {
  border-top: 1px dotted rgba(68, 68, 68, 0.371);
  border-bottom: 1px dotted rgba(37, 37, 37, 0.371);
  padding-top: 15px;
}

.header p {
  font-size: 0.9rem;
  font-weight: 600;
}
p {
  font-size: 0.9rem;
}

.code {
  color: #1163e7;
  cursor: pointer;
}

.unpaid {
  background-color: #ef486a;
  color: #fff;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 0.9rem;
}

.paid {
  padding: 2px 4px;
  border-radius: 3px;
  background-color: #1163e7;
  color: #fff;
  font-size: 0.9rem;
}
.del,
.preview,
.invoice {
  border: none;
  background-color: red;
  padding: 5px;
  width: 30px;
  height: 30px;
  margin-right: 3px;
  border-radius: 50%;
  transition: all 0.2s;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.del {
  background-color: #f7c0cb8f;
  color: #e6264c;
}

.preview {
  background-color: #65d0f74b;
  color: #25bcf1;
}

.invoice {
  background-color: #fbd5624c;
  color: #fbbe08;
}
.del:hover {
  background-color: #f65b7a;
  color: #fff;
}
.preview:hover {
  background-color: #45c7f6;
  color: #fff;
}
.invoice:hover {
  background-color: #fbbe08;
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .background {
    background-color: white;
    border-radius: 5px;
    padding: 2%;
    margin-bottom: 10%;
  }

  .del,
  .preview,
  .invoice {
    border: none;
    padding: 5px;
    width: 35px;
    height: 35px;
    margin-right: 5px;
    border-radius: 50%;
    transition: all 0.2s;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .hide {
    display: block;
  }
}
