.footer a {
  color: rgb(47, 47, 47);
}
.footer {
  background-color: #f3f3f3;
  padding: 88px 0 0 0;
}

.footer .footer-top {
  margin-bottom: 35px;
}
.footer .footer-top .newsletter {
  display: flex;
  align-items: baseline;
  /* background-color: red; */
}
.footer .footer-top .newsletter h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.footer .footer-top .newsletter {
  .input-group {
    margin-left: 8px;
    display: flex;
    flex-wrap: wrap;
    input {
      width: 400px;
      height: 45px !important;
    }
    button {
      font-size: 15px;
      font-weight: 400 !important;
      font-family: 'Work Sans', sans-serif;
      padding-left: 25px;
      padding-right: 25px;
    }
    .text-danger {
      order: 10;
      flex: 0 0 100%;
      padding: 8px 0;
    }
  }
}

.footer .footer-top .newsletter .input-group span {
  margin-right: 10px !important;
}
.footer .footer-main {
  padding-bottom: 64px;
}
.footer .footer-main p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 16px;
}

.footer-bottom {
  background-color: #0c6adf;
  text-align: center;
}
.footer-bottom .footer-copyright {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 20px 0 22px 0;
  color: #ffffff;
}
.footer-bottom .footer-copyright p {
  margin: 0;
  width: 100%;
}
.footer-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 15px;
}
@media (max-width: 992px) {
  .footer {
    padding: 50px 0 0 0;
  }
  .footer .footer-main {
    padding-bottom: 0;
  }
}
/* vertical menu */
.vertical-menu {
  margin-bottom: 16px;

  padding-left: 0;
}

.social-buttons {
  padding-left: 0;
}
.social-buttons li a:hover {
  color: #ffffff !important;
}
.vertical-menu li {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 6px 0;
}
.vertical-menu li i {
  margin-right: 8px;
}
.vertical-menu li a {
  text-decoration: none;
  display: inline-block;
}
.vertical-menu li a i {
  font-size: 16px;
  margin-right: 14px;
}
/* ./vertical menu */

/* social buttons */
.social-buttons {
  display: inline-flex;
}
.social-buttons li {
  margin-right: 7px;
}
.social-buttons li a {
  text-decoration: none;
  padding: 10px;
  font-size: 10px;
  color: #0c6adf;
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  background-color: #ffffff;
  display: inline-block;
  line-height: 0;
}
.social-buttons li a:hover {
  background-color: #0c6adf;
  color: #ffffff;
}
/* ./social buttons */

@media only screen and (max-width: 1200px) {
  .footer .footer-top .newsletter .input-group {
    margin-left: 8px;
    display: flex;
  }
  .footer .footer-top .newsletter .input-group input {
    max-width: 280px;
    height: 45px !important;
  }

  .footer .footer-top .newsletter .input-group button {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 8px 10px 8px 10px !important;
    /* width: 100px; */
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer .footer-top .newsletter .input-group span {
    margin-right: 3px;
  }
}

@media (max-width: 1024px) {
  .footer .footer-top {
    margin-bottom: 10px;
  }
  .footer {
    .footer-top .newsletter {
      padding: 20px 0;
      display: block;
      .input-group {
        margin-left: 0;
        input {
          max-width: 100% !important;
          width: 100%;
         }
         button {
          position: absolute;
          right: 0;
         }
      }
    }
    .vertical-menu,
    .social-buttons {
      margin-bottom: 30px;
    }
  }
}
@media (min-width: 767px) {
  .footer {
    .footer-top .newsletter {
      margin-left: -25px;
    }
    
  }
  
}
@media (min-width: 1024px) {
  .footer .footer-top .newsletter {
    margin-left: -35px;
  }
}