.background {
  background-color: white;
  margin-top: 12px;
  margin-left: 1rem !important;
  margin-right: 5px;
  border-radius: 5px;
  padding-bottom: 30px;
}
.tableData {
  margin-top: 1rem;
}

.tableData thead th {
  /* padding-bottom: 20px !important; */
  border-top: 1px solid rgba(215, 215, 215, 0.796);
  border-bottom: 1px solid rgba(215, 215, 215, 0.796);
  padding-top: 13px;
  padding-bottom: 13px;
}
.tableData small {
  font-size: 0.9rem;
}

.tableData table td {
  padding-top: 25px !important;
}

hr {
  opacity: 0.1;
  z-index: 5;
}

.hide {
  display: none;
}

.header p {
  font-size: 0.9rem;
  font-weight: 600;
}
p {
  font-size: 0.9rem;
}

.code {
  color: #1163e7;
  cursor: pointer;
}

.unpaid {
  color: #ef486a;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 0.9rem;
  margin-right: 0.2rem;
  border: 1px solid #ef486a;
}

.paid {
  padding: 2px 4px;
  border-radius: 3px;
  color: #1163e7;
  font-size: 0.9rem;
  margin-right: 0.2rem;
  border: 1px solid #1163e7;
}

.modalOpen {
  color: #1163e7;
  cursor: pointer;
  border: none;
  padding: 5px;
  width: 35px;
  height: 35px;
  margin-right: 5px;
  border-radius: 50%;
  transition: all 0.2s;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .background {
    background-color: white;
    margin-top: 12px !important;
    margin-left: 10px;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 1024px) {
  .hide {
    display: block;
  }
}
