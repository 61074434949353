.store-wrapper {
    margin-bottom: 30px;

    .store-header {
        .store-banner {
            img {
                border-radius: 5px 5px 0 0;
            }
        }

        .store-avatar {
            height: 60px;
            width: 60px;
            background: #FFFFFF;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.06);
            text-align: center;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 30px;
            margin-top: -30px;
            z-index: 9;
            position: relative;

            img {
                width: auto;
            }
        }
    }

    .store-content {
        background-color: #F8F8F8;
        padding: 30px 30px 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -30px;
        transition: all 0.5s ease-in-out;
        border-radius: 0 0 12px 12px;

        &:hover {
            background-color: #F84D17;

            .vendor-title {
                a {
                    color: #FFFFFF;
                    transition: all 0.5s ease-in-out;
                }
            }

            .store-address {
                color: #fff;
                transition: all 0.5s ease-in-out;
            }

            .store-btn {
                a {
                    border: 2px solid #fff;
                }
            }
        }

        .vendor-title {
            margin-bottom: 5px;
            transition: all 0.5s ease-in-out;

            a {
                font-size: 20px;
                color: #092636;
                line-height: 30px;
                font-weight: 700;
            }
        }

        .store-address {
            font-size: 14px;
            color: #666666;
            line-height: 24px;
            font-weight: 500;

            i {
                margin-right: 6px;
            }
        }

        .store-btn {
            a {
                display: inline-block;
                background-color: #F84D17;
                height: 30px;
                width: 30px;
                border-radius: 40px;
                color: #FFFFFF;
                text-align: center;
                line-height: 24px;
                border: 2px solid #F84D17;
                font-size: 18px;
            }
        }
    }
}

.button-area {
    text-align: center;
    margin-top: 30px;

    .shop-btn {
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.09);
        border: 2px solid #F84D17 !important;
        padding: 15px 35px;
        background-color: #F84D17 !important;
        color: #FFFFFF !important;

        &:hover {
            border: 2px solid #F84D17 !important;
            background-color: #FFFFFF !important;
            color: #f84d17 !important;
        }
    }
}

.nav-tabs {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 27px;
    border: none;

    li {
        .nav-link {
            border: none;
            color: #666666;
            position: relative;
            transition: all 0.5s ease-in-out;

            &:hover {
                color: #F84D17;
                transition: all 0.5s ease;

                &::after {
                    content: "";
                    width: calc(100% - 30px);
                    height: 2px;
                    background-color: #F84D17;
                    position: absolute;
                    bottom: 8px;
                    left: 15px;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}