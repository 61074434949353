.wishTable {
  margin-bottom: 3rem;
}
.wishTable table {
  width: 700px;
  border: 1px solid rgba(128, 128, 128, 0.301);
}

.wishTable thead {
  background-color: #f5f5f5;
}

.wishTable th {
  padding: 5px 15px;
  font-weight: 500;
  font-size: 1.1rem;
}
.wishTable td {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
@media (max-width: 768px) {
  .wishTable td {
    min-width: 250px;
  }
}

.wishTable .image {
  width: 100px;
}

.wishTable .image img {
  width: 80%;
}

.deletebtn {
  font-size: 1.5rem !important;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .wishTable table {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .table-res {
    width: 600px !important;
  }
}
