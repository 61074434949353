.checkoutContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}
.cartEmpty{
	font-size: 16px;
	font-style: italic;
}
.cartDetails {
  order: 1;
  margin-bottom: 2rem;
}
.checkoutForm {
  order: 2;
}

@media only screen and (min-width: 768px) {
  .checkoutContainer {
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-gap: 2rem;
  }

  .cartDetails {
    margin-bottom: 0;
    margin-top: 0;
    order: 2;
  }
  .checkoutForm {
    order: 1;
  }
}
