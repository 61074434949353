.bottom {
  position: absolute;
  /* right: 10px; */

  left: 25px;
  bottom: -610px;
}

.right {
  position: absolute;
  right: 10px;
}
