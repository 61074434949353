.banner-image {
	text-align: right;
	margin-bottom: 32px;

	@media(max-width: 1024px) {
		text-align: left;
	}
}

.banner-image img {
	width: auto;
}

.banner-wrapper {
	background-color: transparent;
	background-image: linear-gradient(0deg, #121996 100%, #112bc2 100%);
	transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
	margin: 0px;
	padding: 63px 0px 63px 0px;
	border-radius: 6px 0px 0px 6px;

	@media(max-width: 1024px) {
		margin: 0 10px 30px;
		border-radius: 6px;
	}
}

.heading-wrap {
	padding: 0 40px 20px;
}

.heading-wrap .banner-title {
	color: #006b9800;
	font-size: 48px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 40px;
	-webkit-text-stroke-width: 1.5px;
	stroke-width: 1.5px;
	-webkit-text-stroke-color: #ffc601;
	stroke: #ffc601;
}

.heading-wrap .banner-subtitle {
	color: #ffffff;
	font-size: 40px;
	font-weight: 800;
	line-height: 46px;
	margin-bottom: 15px;
}

.heading-wrap .banner-desc {
	color: #ffffff;
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
}

.offer-area {
	height: 200px;
	width: 200px;
	background-color: #FFDF00;
	border-radius: 100%;
	margin: 0 auto;
	text-align: center;
	position: relative;
	margin-right: 50px;

	@media(max-width: 1024px) {
		margin-left: 30px;
	}

	&::before {
		content: '';
		height: calc(100% - 20px);
		width: calc(100% - 20px);
		border: 1px solid #fff;
		position: absolute;
		left: 10px;
		top: 10px;
		border-radius: 100px;
		z-index: 1;
	}

	&::after {
		content: '';
		height: 80px;
		width: 46px;
		position: absolute;
		left: 80px;
		bottom: -27px;
		background: #FFDF00;
		border-top-left-radius: 100px;
		transform: rotate(188deg);
		border-bottom-right-radius: 100px;
		z-index: 0;
	}

	.offer-wrap {
		text-align: center;
		padding-left: 25px;
		padding-top: 10px;

		p {
			font-size: 20px;
			position: relative;
			top: 45px;
			text-align: left;
			left: 18px;
			font-weight: 500;
		}

		.value {
			font-size: 72px;
			font-weight: 700;
		}

		.percentage {
			font-size: 36px;
			font-weight: 700;
			position: relative;
			top: -20px;
			left: 5px;
		}

		.text {
			font-size: 20px;
			font-weight: 500;
			position: relative;
			left: -25px;
		}
	}
}

@media(max-width: 1024px) {
	.button-wrap {
		text-align: left !important;
	}
}