.addBtnParent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: auto !important;
  margin-right: 0.3rem;
  margin: 1.5rem 0;
}

.addBtnParent a {
  text-decoration: none;
}
.addBtn {
  width: 12rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  transition: all 0.3s;
  background-color: #fff;
  cursor: pointer;
}

.addBtn:hover {
  box-shadow: 1px 5px 15px rgba(128, 128, 128, 0.319);
}

.icon {
  font-size: 2rem;
  color: black;
}

.addBtn h6 {
  color: #1163e7;
}
@media only screen and (min-width: 768px) {
  .addBtnParent {
    height: 15rem;
    flex-direction: row;
  }

  .addBtn {
    width: 16rem;
    height: 8rem;
  }
}
