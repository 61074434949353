.topMenu {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.topMenu menu {
  display: grid;
  grid-template-columns: 5fr 8fr;
  grid-gap: 1rem;
  align-items: center;
}
.topMenu a {
  text-decoration: none;
  border: 1px solid rgba(128, 128, 128, 0.366);
  padding: 8px;
  border-radius: 5px;
  display: flex;
  width: 150px;
  align-items: center;
  background-color: #fff;
  font-weight: 600;
}

.navbar_mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.navbar_mobile div {
  display: flex;
  justify-content: center;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  padding: 1rem 0;
  font-size: 1.1rem;
  cursor: pointer;
}
.navbar_mobile div a {
  color: black;
}

.nav_links {
  position: absolute;
  left: 0;
  transform: translateX(0);
  /* height: 80vh; */
  transition: all 0.2s;
  border-radius: 6px;
}

.hide {
  opacity: 0;
  /* width: 0px !important; */
  transition: all 0.2s;
  transform: translateX(-500px);
  z-index: 3;
}
.show {
  background-color: #fff;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 1;
  transform: translateX(0);
  z-index: 5;
  transition: all 0.2s;
}

.content {
  height: calc(100vh - 87px);
  overflow-y: scroll;
}
@media only screen and (min-width: 768px) {
  .show {
    width: 42%;
  }
  .topMenu menu {
    display: grid;
    grid-template-columns: 2fr 10fr;
    grid-gap: 1rem;
    align-items: center;
  }
}

@media only screen and (min-width: 1024px) {
  .show {
    width: 34%;
  }

  .topMenu menu {
    display: grid;
    grid-template-columns: 1fr 10fr;
    grid-gap: 1rem;
    align-items: center;
  }
}

@media only screen and (min-width: 1220px) {
  .layoutContainer {
    display: grid;
    grid-template-columns: 300px auto;
    grid-row-gap: 0;
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;
  }
  .navbar_mobile {
    display: none !important;
  }
  .content {
    height: 100vh;
    /* overflow: scroll; */
    background-color: transparent;
  }

  .nav_links {
    position: relative;
    background-color: #fff;
    position: relative;
    width: auto !important;
    height: 100vh;
    /* overflow: scroll; */
    opacity: 1;
    transform: translateX(0);
  }

  .topMenu menu {
    display: grid;
    grid-template-columns: 1fr 11fr;
    grid-gap: 1rem;
  }
}
