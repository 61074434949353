.comparison-table-wrap {
  overflow-x: auto;
}
.compare-table {
  background-color: transparent;
  width: 100%;
  margin-bottom: 15px;
  font-size: .9em;
  border-spacing: 0;
  border-collapse: collapse;
}
.compare-table,
.compare-table tr,
.compare-table td {
  border: 1px solid #ddd;
}

.compare-table tr td:first-child {
  width: 130px;
  height: 50px;
  background-color: #f8f8f8;
}
.compare-table-img {
  width: 190px;
  margin: 20px auto;
  display: block;
}
.qt-add-to-cart-btn {
  cursor: pointer;
  user-select: none;
  background-color: #2d73e7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: #fff;
  width: 250px;
  margin: 0 auto;
  border-radius: 3px;
}
button.ct-remove-btn {
  border: none;
  background-color: #ff7171;
  padding: 8px 20px;
  color: #fff;
  font-weight: 300 !important;
  font-size: 14px;
  width: 170px;
  margin: 0 auto;
  display: block;
  border-radius: 3px;
}
