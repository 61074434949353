.BlogSidebarContainer {
  padding: 0;
}

.search {
  position: relative;
}

.search button {
  position: absolute;
  height: 45px;
  width: 35px;
  right: 10px;
  background-color: inherit;
  border: none;
}
.listTitle h4{
	font-size: 22px;
	color: #222222;
	font-weight: bold;
	padding: 5% 0;
}

.BlogSidebarContainer {
  padding: 0;
}
.search {
  position: relative;
}

.search button {
  position: absolute;
  height: 45px;
  width: 35px;
  right: 10px;
  background-color: inherit;
  border: none;
}

.categories {
  border-bottom: 1px solid rgba(128, 128, 128, 0.307);
}

.categories a {
  display: block;
  text-decoration: none;
  padding-bottom: 0.5rem;
  color: black;
  opacity: 0.6;
}

.categories a:hover {
  color: #2e74e8;
  opacity: 1;
}
.recentPost {
  border-bottom: 1px solid rgba(128, 128, 128, 0.266);
}

.recentPost h4 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.recentPost a {
  text-decoration: none;
  color: black;
}
.recentPost a:hover {
  color: black;
}

.recentPostCard {
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-gap: 10px;
}

.recentPostCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recentPostWrap > a {
  margin-bottom: 15px;
  display: block;
}

.tags h4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.tags a {
  background-color: rgba(128, 128, 128, 0.063);
  padding: 2px 5px;
  color: gray;
  border-radius: 3px;
  text-align: center;
  transition: all 0.2s;
  text-decoration: none;
  display: inline-block;
  margin: 0 5px 5px;
}

.tags a:hover {
  background-color: #2e74e8;
  cursor: pointer;
  color: #fff;
}
