.spacing {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}
.topSection{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto !important;
  margin-right: 0.3rem;
  margin: 1.5rem 0;
}