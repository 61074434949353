.layoutContainer {
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

@media only screen and (min-width: 768px) {
  .layoutContainer {
    display: grid;
    grid-template-columns: 4fr 10fr;
  }
}
