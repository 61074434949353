.header_logo {
	max-width: 166px;
	margin-right: 40px;

	@media(max-width: 767px) {
		max-width: 130px;
		margin-right: 15px;
	}
}

.search-btn {
	background: #F84D17;
	border-color: #F84D17;
}

.search-input-group {
	display: flex;
	position: relative;
	@media(max-width: 767px) {
		margin-bottom: 30px;
	}
}

.search-input {
	height: 44px;
	border-style: solid;
	border-width: 2px 2px 2px 2px;
	overflow: hidden;
	border-radius: 4px 4px 4px 4px;
}

.logo-and-delivery-area {
	display: flex;
	align-items: center;

	.logo {
		margin-right: 15px;
	}

	@media(max-width: 767px) {
		align-items: center;
		justify-content: space-between;
		padding-bottom: 15px;
	}
}

.delivery-area {
	display: flex;
	align-items: center;

	.icon {
		height: 40px;
		width: 40px;
		background-color: #F84D17;
		border-radius: 100px;
		color: #fff;
		line-height: 40px;
		text-align: center;
		font-size: 18px;
		margin-right: 10px;
	}

	.content {

		p {
			margin: 0;
			color: #666;
			font-size: 13px;
		}

		.location {
			color: #092636;
			font-size: 14px;
			font-weight: 600;
		}
	}
}

.search-input-group {
	border: 2px solid #F84D17;
	border-radius: 5px;
	display: flex;
	align-items: center;
	font-size: 13px;

	@media(min-width: 1800px) {
		display: inline-flex;
	}

	input {
		border: none;
		border-right: 2px solid #D8E1E6;
		border-radius: 0;
		height: 30px;
		padding: 10px 20px;
		margin-right: 10px;
		width: 80%;
		&:focus {
			outline: none;
		}
	}

	select {
		color: #092636;
		border: none;
		width: 20%;
		margin-right: 8px;
		&:focus {
			outline: none;
		}
	}

	.search-btn {
		height: 50px;
		width: 75px;
		background-color: #F84D17;
		color: #fff;
		border: 1px solid #F84D17;
	}
}

.list-inline {
	.currency {
		select {
			border: none;
			font-size: 13px;
			color: #092636;
			font-weight: 400;
			height: 45px;

			&:focus {
				outline: none;
			}
		}
	}

	li {
		a {
			display: block;
			text-align: center;
		}

		i {
			font-size: 22px;
		}

		p {
			font-size: 12px;
			font-weight: 600;
		}
	}

	.comparison div,.wishlist div,.cart {
		position: relative;
		text-align: center;

		.count {
			height: 18px;
			width: 18px;
			background-color: #F84D17;
			font-size: 11px;
			border-radius: 10px;
			color: #fff;
			position: absolute;
			top: -7px;
			right: 0;
			text-align: center;
			line-height: 18px;
		}
	}
	.comparison div{
		.count {
			right: 10px;
			top: -7px;
		}
	}
	.wishlist div{
		.count {
			top: -6px;
			right: -6px;
		}
	}
	.cart {
		.count {
			top: -6px;
			right: -1px;
		}
	}
}

.compare-icon{
	font-size: 22px;
}
.padding-none {
	padding: 0 !important;
}

.navbar-expand-lg {

	.navbar-nav {
		li {

			a {
				color: #fff;
				font-size: 16px;
				font-weight: 300;
				padding: 0 15px;

				i {
					margin-right: 10px;
				}

				&:hover {
					color: rgba(255, 255, 255, 0.7);
				}

				&::after {
					display: none;
				}

				&:active {
					color: #fff;
				}

				&:focus {
					color: rgba(255, 255, 255, 0.7);
				}
			}
		}

		>li {
			&:hover {
				.dropdown-menu {
					display: block;

					.nav-item {
						padding: 10px 10px;

						a {
							color: #092636;
						}
					}
				}
			}
		}
	}
}


@media(max-width: 1024px) {
	.logo-and-delivery-area {
		justify-content: space-between;
		margin-bottom: 30px;
	}
}

@media(max-width: 767px) {
	.float-end {
		float: none !important;
		position: relative;
		left: -15px;
	}
}
.category-menu .dropdown a.nav-link{
	color: #fff;
}
ul.dropdown-menu{
	padding: 0;
}
.nav-link > span > svg{
	width: 20px !important;
	height: 20px !important;
}
.nav-link > span > svg:hover,
.nav-link > span > svg:focus{
	color: #F84D17;
}