.breamcumbContainer {
  background-color: #954BEB;
  margin-top: 0;
  height: 170px;
  margin-bottom: 5%;
}

.breamcumbContainer a {
  text-decoration: none;
}
.headerBreadcrumb{
	text-align: center;
	padding-top: 4%;
}
.headerBreadcrumb li{
	padding-right: 6px;
}
.headerBreadcrumb li a{
	color: #fff;
}