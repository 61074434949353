.dual-banner-wrapper {
    padding: 50px 0 50px;
}

.banner-area {
    background: linear-gradient(120.75deg, #FAA426 21.06%, #FFE401 93.58%);
    padding: 70px 50px 75px;
    border-radius: 12px;

    @media(max-width: 767px) {
        margin-bottom: 30px;
    }

    .subtitle {
        color: #5019A8;
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
    }

    .title {
        font-size: 44px;
        color: #fff;
        font-weight: 800;
        line-height: 46px;
        margin-bottom: 20px;
    }

    .offer {
        font-size: 20px;
        line-height: 20px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 40px;

        span {
            color: #092636;
            font-size: 32px;
            font-weight: 700;
            position: relative;
            top: 5px
        }
    }

    .shop-btn {
        padding: 15px 25px;
        background-color: #092636;
        font-size: 14px;

        &:hover {
            background-color: #fff;
            color: #092636;
        }
    }
}

.banner-area2 {
    background: #F5CBD3;
    padding: 70px 50px 75px;
    border-radius: 12px;

    .bold-text {
        font-size: 48px;
        line-height: 40px;
        stroke: #092636;
        stroke-width: 1.5px;
        color: rgba(0, 107, 152, 0);
        -webkit-text-stroke-width: 1.5px;
        -webkit-text-stroke-color: #092636;
        stroke: #092636;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    h3 {
        font-size: 44px;
        line-height: 44px;
        font-weight: 800;
        margin-bottom: 25px;
    }

    p {
        color: #666666;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 38px;
    }

    .shop-btn {
        padding: 15px 25px;
        background-color: #092636;
        font-size: 14px;

        &:hover {
            background-color: #fff;
            color: #092636;
        }
    }
}