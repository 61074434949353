.productInfo {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.productInfo h4 {
  font-size: 1.4rem;
  font-weight: 600;
}

.infoContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

.infoContainer .image img {
  width: 100%;
}

.infoTable {
  border: 1px solid rgba(128, 128, 128, 0.342);
  padding: 0 1.5rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.infoContainer table {
  width: 100%;
}
.infoContainer tr {
  padding: 10px;
}
.infoContainer td:first-child {
  border-right: 1px solid rgba(128, 128, 128, 0.342);
}

.infoContainer td {
  border-bottom: 1px solid rgba(128, 128, 128, 0.342);
}

.lastData {
  border-bottom: none !important;
}
.infoContainer .tableHeader {
  padding-bottom: 0.7rem;
  padding-right: 1rem;
  padding-top: 1.1rem;
  font-weight: 600;
}

.infoContainer .tableData {
  padding-left: 1rem;
  padding-bottom: 0.7rem;
  padding-top: 1.1rem;
  color: gray;
}
@media only screen and (min-width: 768px) {
  .infoContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}
