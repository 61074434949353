.style-2 {
    background-color: #5019A8 !important;
    background-image: none !important;
    border-radius: 6px !important;
    padding: 44px 0px 24px 0px !important;

    @media(max-width: 1024px) {
        margin: 0 12px 45px !important;
        padding: 30px 20px !important;
    }

    .heading-wrap {
        padding: 0 30px 7px;

        .banner-title {
            font-size: 40px;
            -webkit-text-stroke-color: #ffffff;
            stroke: #ffffff;
        }

        .banner-subtitle {
            font-size: 36px;
        }
    }

    .banner-image {
        margin-bottom: 0;
        text-align: center;
        padding-top: 20px;

        @media(max-width: 1024px) {
            text-align: left;
            margin-left: 15px;
        }
    }
}

.button-wrap {
    padding-left: 30px;

    .shop-btn {
        padding: 14px 25px 13px 25px;
    }
}