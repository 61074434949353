.header_menu {
	height: 60px;
	background-color: #092042;
	color: #fff;
	z-index: 9999;
	padding: 0 23px;

	@media(max-width: 767px) {
		height: 100px;
		padding: 10px 0;
	}
}

.category-menu-area {
	padding: 10px 0px;
}

.nav-menu-2 {

	.navbar-nav {
		li {

			a {
				color: #fff;
				font-size: 14px;
				font-weight: 500;
				padding: 0 15px;

				i {
					margin-right: 5px;
				}

				&:hover {
					color: rgba(255, 255, 255, 0.7);
				}

				&::after {
					display: none;
				}

				&:active {
					color: #fff;
				}

				&:focus {
					color: rgba(255, 255, 255, 0.7);
				}
			}
		}

		>li {
			&:hover {
				.dropdown-menu {
					display: block;
					top: 40px;

					.nav-item {
						padding: 10px 10px;

						a {
							color: #092636;
						}
					}
				}
			}
		}
	}
}

.nav-menu-2 {
	padding-left: 50px;

	.navbar-nav {
		li {
			padding: 10px 10px;

			.dropdown-menu {
				li {
					a {
						&:hover {
							color: #F84D17 !important;
						}
					}
				}
			}
		}
	}
}

.right-1-menu {
	display: flex;
	justify-content: space-between;
}

.address-area {
	display: flex;
	align-items: center;
	padding-left: 50px;

	p {
		display: inline-block;
		margin: 0;
		font-size: 15px;
		color: #fff;
		font-weight: 700;
	}

	span.phone-label {
		font-size: 13px;
		color: #C7C7C7;
		font-weight: 500;
		margin-right: 10px;
	}

	span.phone {
		font-size: 15px;
		color: #fff;
		font-weight: bold;
		margin-right: 10px;
	}

	.icon {
		height: 36px;
		width: 36px;
		background-color: #F84D17;
		text-align: center;
		line-height: 36px;
		margin-right: 15px;
		border-radius: 30px;
	}
}

.category-menu {

	.navbar-nav {
		width: 100%;
	}

	.dropdown {
		width: 100%;
		position: relative;
		top: -8px;

		@media(max-width: 767px) {
			padding-bottom: 0;
		}

		.nav-link {
			padding: 18px 25px 18px !important;
			background: rgba(255, 255, 255, 0.13);
			position: relative;
			top: -10px;
		}
	}

	.dropdown-menu {
		top: 48px !important;
		position: relative;
		z-index: 9;
		border-top-right-radius: 0;
		border-top-left-radius: 0;

		.nav-link {
			padding: 0 !important;
		}

		@media(max-width: 1024px) {
			top: 10px !important;
		}

		@media(max-width: 767px) {
			top: 15px !important;
		}

		li {
			width: 100%;
			display: inline-block;
			a {
				&:hover {
					color: #F84D17 !important;
				}

				i {
					margin-right: 10px;
				}
			}
		}
	}
}

// category css
.temp-1-cat-list {
	border-bottom: 1px solid #eeee;
}

li.temp-1-cat-list:last-child {
	border-bottom: none;
}

li.temp-1-cat-list a {
    margin: 20px 0 7px 10px !important;
	color: #092042 !important;
	font-size: 14px !important;
}

.temp-1-cat-list span {
	padding-left: 12px;
}

@media(max-width: 767px) {
	.address-area {
		display: none;
	}
}