.name {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
  align-items: center;
}
.emailPhone {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}
.email {
  display: grid;
  grid-template-columns: 1fr;
}
.address {
  margin-top: 1%;
}
.submitBtn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
}

.submitBtn a {
  text-decoration: none;
}

.shopName {
  padding: 8px 1rem;
}
.shopName h4 {
  font-size: 1rem;
  padding-top: 5px;
}

.productInfo {
  display: grid;
  grid-template-columns: 3fr 6fr 3fr;
  grid-gap: 8px;
  align-items: center;
  padding: 2rem 1rem 0 1rem;
  background-color: #f8f9fa;
}


.productInfo h5 {
  font-size: 1rem;
}
.productInfo small {
  font-size: 0.8rem;
}

.productInfoImg {
  position: relative;
}

.imageTop {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: gray;
  position: absolute;
  top: -7px;
  right: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.9rem;
}
.productInfoImg img {
  background-color: #fff;
  width: 70%;
  border: 1px solid rgba(128, 128, 128, 0.297);
  border-radius: 3px;
}

.productInfoPrice {
  display: flex;
  justify-self: end;
}

@media only screen and (min-width: 768px) {
  .name {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }

  .email {
    display: grid;
    grid-template-columns: 1fr;
  }
  .phone {
    display: grid;
    grid-template-columns: 1fr;
  }
  .emailPhone {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 2rem;
  }
}
