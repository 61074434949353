.csvFileInputContainer {
  position: relative;
  cursor: pointer;
}
.csvFileInput {
  position: absolute;
  right: 0;
  z-index: 50;
  opacity: 0 !important;
  left: 0;
  cursor: pointer;
}
.upContainer {
  background-color: #fff;
  margin-top: 1rem;
  border-radius: 3px;
}

.upContainer h5 {
  padding: 1rem;
  border-bottom: 1px dotted gray;
}

.upContainer .uploadCSVfile {
  padding: 1rem;
}

.submitbutton {
  padding: 1rem;
  margin-top: -20px;
}
