.titles h1 {
  position: relative;
  margin-bottom: 3rem;
}
.titles h1::after {
  content: "";
  height: 0;
  width: 0;
  background-color: rgba(53, 53, 53, 0.647);
  position: absolute;
  bottom: -10px;
  left: 0;
}
.content {
  text-align: justify;
}

@media only screen and (min-width: 1200px) {
  .titles h1::after {
    content: "";
    height: 2px;
    width: 210px;
    background-color: rgba(53, 53, 53, 0.647);
    position: absolute;
    bottom: -10px;
    left: 42%;
  }
}
