.preview {
	background-color: #65d0f74b;
	color: #25bcf1;
}
.preview:hover {
background-color: #45c7f6;
color: #fff;
}
.tableData {
  width: 100%;
}
.tableData table {
  min-width: 500px;
}
.tableData small {
  font-size: 0.9rem;
}
.tableData {
  margin-top: 1rem;
  padding-left: 1px !important;
}

.tableData thead th {
  border-top: 1px solid rgba(215, 215, 215, 0.796);
  border-bottom: 1px solid rgba(215, 215, 215, 0.796);
  padding-top: 13px;
  padding-bottom: 13px;
}
.tableData table td {
  padding-top: 25px !important;
  border-bottom: 1px solid rgba(215, 215, 215, 0.796);
  padding-bottom: 10px !important;
}

.tableData button {
  background-color: inherit;
  border: none;
  padding: 0;
  font-size: 1.5rem;
  margin-top: -8px;
}

@media only screen and (min-width: 768px) {
  .tableData table {
    min-width: 100%;
  }
}
