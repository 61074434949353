.titles {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 3rem;
}

.search {
  display: flex;
  height: 45px;
}

.search input {
  border-radius: 8px 0 0 8px;
  border: 1px solid rgba(128, 128, 128, 0.44);
  border-right: none;
}
.search input:focus {
  border: 1px solid #0b5ed7;
}

.search button {
  border-radius: 0 8px 8px 0;
  border: 1px solid rgba(128, 128, 128, 0.44);
}

.titles h1 {
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
}
.titles h1::after {
  content: "";
  height: 0;
  width: 0;
  background-color: rgba(53, 53, 53, 0.647);
  position: absolute;
  bottom: -10px;
  left: 0;
}

.slideItem {
  border: 1px solid rgba(128, 128, 128, 0.346);
  border-radius: 10px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 90px;
  color: rgba(0, 0, 0, 0.658);
}
.slideItem img{
width: 100%;
}
.slideItem:hover {
  box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.313);
}

.slideContainer h5 {
  text-align: center;
  padding-top: 0.7rem;
  opacity: 0.7;
  padding-bottom: 3px;
}
@media only screen and (min-width: 768px) {
  .titles h1 {
    text-align: center;
    padding-left: 180px;
  }
  .titles {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
}

@media only screen and (min-width: 1200px) {
  .titles h1 {
    text-align: center;
    padding-left: 430px;
  }
  .titles h1::after {
    content: "";
    height: 2px;
    width: 170px;
    background-color: rgba(53, 53, 53, 0.647);
    position: absolute;
    bottom: -10px;
    left: 65%;
  }
}
