.active {
  background-color: #e2e4f6c2;
  border-radius: 3px;
}

.icon_green {
  color: #1163e7;
}
.icon_blue {
  color: #1163e7;
}

.nav_links {
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding-bottom: 1rem;
  height: 100vh;
  overflow-y: scroll;
}

.seller_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}
.nav_links .seller_info h1 {
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}
.seller_info button {
  font-size: 1rem;
  border: none;
  display: block;
  background-color: inherit;
}

.nav_links ul {
  list-style-type: none;
}

.nav_links ul li a {
  color: #353535;
  text-decoration: none;
  /* padding: 0 2rem; */
  display: block;
}
.nav_links ul li a:hover {
  color: #353535;
}
.nav_links li {
  transition: all 0.1s;
  padding: 0.5rem 0;
  padding-left: 2rem;
  position: relative;
  font-size: 1rem;
}

.nav_links li:hover {
  background-color: #e2e4f6c2;
  cursor: pointer;
}
.insideNavUl li {
  padding: 0;
}

.inside_nav {
  padding: 0.5rem;
  padding-left: 1.5rem;
}
.insideNavUl {
  padding: 0;
}

.inside_nav:hover {
  background-color: #e2e4f6c2;
  transition: all 0.2s;
  border-radius: 5px;
}

.openProduct {
  height: 220px;
  overflow: hidden;
  transition: all 0.3s;
}

.openRefund {
  min-height: 100px;
  overflow: hidden;
  transition: all 0.3s;
}

.close {
  height: 0;
  overflow: hidden;
  transition: all 0.2s;
}
.right {
  position: absolute;
  right: 2rem;
}

@media only screen and (min-width: 1220px) {
  .seller_info button {
    display: none;
  }
  .nav_links {
    padding-bottom: 0;
    margin: 0;
    height: 100vh;
  }

  .nav_links .seller_info h1 {
    font-size: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 1rem;
  }
}
