.content-block {
	padding: 50px 0px 50px 0px;
	overflow: visible;
}

.content-title {
	color: #092636;
	margin: 0px 0px 47px 0px;
	font-size: 30px;
	font-weight: 700;
	line-height: 40px;

	@media(max-width: 767px) {
		font-size: 22px;
		line-height: 32px;
	}
}

.view-all {
	padding: 0px 0px 0px 0px;
	font-family: "Rubik", Sans-serif;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	color: #092636;
	background-color: #02010100;
	border-style: none;
}

.category-slider-image img {
	border-radius: 6px 6px 6px 6px;
	height: auto;
	width: auto;

	@media(max-width: 1024px) {
		height: 100%;
		width: 100%;
	}
}

.category-item-wrapper {
	width: 275px;
	margin-right: 0px;
}

.category-item-wrapper:last-child {
	margin-right: 0px;
}

.category-item {
	position: relative;
}

.category-title {
	color: #000000;
	background-color: #ffffff;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize;
	line-height: 24px;
	padding: 9px 15px 10px 15px;
	margin: 0px 10px 10px 10px;
	border-radius: 6px;
	transition: all 0.5s ease-in-out;
}

.category-title:hover {
	background-color: #f84d17;
	color: #ffffff;
}

.title_on_top {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: absolute;
	left: 0;
	bottom: 0;
	width: calc(100% - 45px);

	@media(max-width: 1024px) {
		width: calc(100% - 20px);
	}
}

.category-slider {
	margin: 0;

	.swiper-button-prev {
		left: 0px;
	}
}