.profileContainer {
  border: 1px solid rgba(128, 128, 128, 0.205);
  padding: 0.9rem;
  border-radius: 5px;
  font-size: 0.9rem;
}
.profileContainer label {
  margin-bottom: 5px;
}
.profileContainer input {
  /* height: 30px; */
  padding: 10px;
  margin-bottom: 10px;
}

.reactDatePickerContainer {
  display: block !important;
  width: 100% !important;
}
.reactDatePickerContainer input {
  padding: 13px;
  margin-top: 6px;
  height: 0px;
}
.reactDatePickerContainer > div {
  border: 1px solid #ddd !important;
  border-radius: 3px !important;
  padding-left: 10px;
}
