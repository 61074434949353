.footer1-menu{
	padding-left: 0;
}
.footer-area {
    background-color: #001A22;
}
.social-icons{
    padding: 0;
}
.footer-widget {
    padding: 60px 0 60px 20px;

    @media(max-width: 1024px) {
        padding: 60px 0 0px;
    }

    .footer-title {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    .footer-menu {

        li {
            display: block;
            margin-bottom: 12px;

            a {
                display: inline-block;
                color: #fff;
                font-size: 15px;
                line-height: 24px;
            }
        }
    }

    .logo {
        width: 240px;
        margin-bottom: 30px;
    }

    .content-details {
        .content {
            color: #fff;
            margin-bottom: 25px;
        }

        .subscribe-form {
            display: flex;

            input {
                width: 100%;
                height: 50px;
                padding-left: 15px;
                color: #001A22;
                border-radius: 6px 0px 0px 6px;

                border: 1px solid #fff;

                &::placeholder {
                    color: #5D6171;
                }

                &:focus {
                    outline: none;
                }
            }

            .btn {
                background-color: #F84D17;
                border: 1px solid #f84d17;
                border-radius: 0 6px 6px 0;
                position: relative;
                height: 50px;
                width: 50px;
            }
        }

        .social-wrapper {
            margin-top: 33px;

            .social-title {
                color: #fff;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
            }

            .social-icons {
                li {
                    display: inline-block;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        height: 44px;
                        width: 44px;
                        display: inline-block;
                        color: #fff;
                        border-radius: 100px;
                        line-height: 44px;
                        text-align: center;
                    }

                    .facebook {
                        background-color: #1877F2;

                        &:hover {
                            background-color: #0969e7;
                        }
                    }

                    .twitter {
                        background-color: #1DA1F2;

                        &:hover {
                            background-color: #0585d4;
                        }
                    }

                    .instagram {
                        background-color: #FD1D1D;

                        &:hover {
                            background-color: #ec0505;
                        }
                    }

                    .linkedin {
                        background-color: #0077B5;

                        &:hover {
                            background-color: #016296;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 768px) {
    .border-right {
        border-right: 1px solid #0D303A;
    }

    .pl-60 {
        padding-left: 60px !important;
    }
}

@media(max-width: 1024px) {
    .border-right {

        &.border-none {
            border-right: none;
        }
    }

    .pl-60 {
        &.padding-none {
            padding-left: 10px !important;
        }
    }
}

.download-payment-area {
    background-color: rgba(255, 255, 255, 0.04);
    padding: 30px 0;

    h2 {
        font-size: 16px;
        color: #fff;
        line-height: 20px;
        text-transform: uppercase;
        margin-right: 15px;
        margin-bottom: 0;
    }

    .download-area {
        display: flex;
        align-items: center;

        .download-list {
			display: flex;
            div {
                a {
					display: flex;
                    width: 112px;
                    margin-right: 10px;
                    img {
                        border-radius: 6px;
                    }
                }
            }
        }
    }

    .payment-area {
        display: flex;
        align-items: center;
        padding-top: 5px;

        @media(min-width: 1024px) {
            justify-content: flex-end;
        }

        @media(max-width: 1024px) {
            margin-top: 30px;
        }
    }
}

.copyright-area {
    padding: 30px 0;

    .copy-right {
        color: #fff;
        font-size: 16px;
        line-height: 20px;

        p {
            margin-bottom: 0;
        }

        a {
            color: #fff;
        }
    }

    .usefull-links {
        text-align: right;

        @media(max-width: 767px) {
            text-align: left;
            margin-top: 15px;
            padding-left: 10px;
        }

        li {
            a {
                color: #fff;
                margin-right: 15px;
                position: relative;

                &::before {
                    content: '';
                    height: 3px;
                    width: 3px;
                    background-color: #fff;
                    position: absolute;
                    left: -8px;
                    top: 11px;
                    border-radius: 5px;
                }
            }
        }
    }
}