.mb-20 {
	margin-bottom: 20px;
}
.view-all a {
	font-family: "Rubik", Sans-serif;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
}

.button-wrap a {
	color: #fff;
	padding: 17px 25px 16px 25px;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	background-color: #f84d17;
	border-style: none;
	box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);
}
