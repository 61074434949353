.icon_size{
	height: 20px;
	width: 20px;
	padding-right: 2px;
}
.slick-slider {
  margin: 0 -23px;
}
.slick-slide > div {
  margin: 0 12px;
}
.mini-slide {
  text-align: center;
}
.mini-slide p {
  color: #000;
  font-weight: 500;
  margin-top: 8px;
}
.mini-slide .image {
  border-radius: 10px;
  background-color: #f3f3f3;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mini-slide img {
  width: 40%;
  height: auto;
}

.category-wrapper .category-list {
  padding: 20px 30px 35px 40px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  ul {
    li {
      a {
        span {
          svg {
            margin-right: 10px;
            display: inline-block;
            margin-top: -4px;
          }
        }
      }
    }
  }

}
.category-list ul {
  padding: 0 !important;
}
.category-wrapper .category-list a {
  text-decoration: none !important;
  color: #333333 !important;
  text-transform: capitalize;
}

.category-wrapper .category-list a:hover {
  color: #2e74e8 !important;
}
.category-wrapper .category-list > ul > li {
  border-bottom: 1px solid #d7d7d7;
  position: relative;
  display: block;
}
.category-wrapper .category-list > ul > li a {
  font-size: 15px;
  line-height: 18px;
  padding: 13px 0;
  display: block;
}
.category-wrapper .category-list ul {
  padding: 0 !important;
  z-index: 5 !important;
  border-radius: 8px;
}

.category-wrapper .category-list > ul > li a i {
  margin-right: 15px !important;
  font-size: 18px;
}
.category-wrapper .category-list > ul > li > ul {
  display: none;
  transition: all 0.3s linear;
  position: absolute;
  left: calc(100% + 21px);
  top: -1px;
  background-color: #ffffff;
  min-width: 250px;
  z-index: 100 !important;
}

.category-wrapper .category-list > ul > li > ul > li {
  padding: 0px 25px !important;
  padding-left: 25px !important;
}
.category-wrapper .category-list > ul > li:hover a i {
  margin-right: 14px;
  transition: all 0.3s linear;
}
.category-wrapper .category-list > ul > li:hover > ul {
  display: block;
}
.category-wrapper .category-list > ul > li > ul > li:hover {
  background-color: #d9e8fb !important;

  display: block;
}
.category-wrapper .category-list > ul > li:hover > ul > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.208);
}
.category-wrapper .category-list > ul > li > ul > li:last-child {
  border-bottom: 1px solid transparent;
}
.category-wrapper .category-list > ul > li:hover a i {
  margin-right: 14px;
  transition: all 0.3s linear;
}
.category-wrapper .category-list > ul > li:hover > ul {
  display: block;
  overflow: hidden;
}
.category-wrapper .category-list > ul > li:last-child {
  border: none;
}

.category-wrapper .category-slider {
  height: 100%;
}

.category-wrapper .category-slider .swiper-slide {
  height: 100%;
  border: none;
  align-items: flex-start;
  border-radius: 10px;
  overflow: hidden;
}
.category-wrapper .category-slider .swiper-slide img {
  height: 360px;
  object-fit: cover;
  width: 100%;
}
.category-wrapper .category-slider .swiper-slide .slider-content {
  position: absolute;
  top: 30px;
  right: 40px;
  text-align: right;
}
.category-wrapper .category-slider .swiper-slide .slider-content h3 {
  font-size: 40px;
  margin-bottom: 12px;
  color: #777777;
  line-height: 40px;
  font-weight: 700;
  font-weight: "Roboto";
}
.category-wrapper
  .category-slider
  .swiper-slide
  .slider-content
  .button-outline {
  font-size: 20px;
  color: #000;
  padding: 5px 15px;
  text-transform: none;
  font-weight: 400;
  background: transparent;
  border: 1px solid #000;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
}
.category-wrapper
  .category-slider
  .swiper-slide
  .slider-content
  .button-outline:hover {
  color: #000;
  text-decoration: none;
}
.category-wrapper .category-slider .swiper-pagination {
  bottom: 40px !important;
  margin: 0 !important;
}
.category-wrapper .category-slider .swiper-button-next,
.category-wrapper .category-slider .swiper-button-prev {
  top: 50%;
}

.swiper-button-next, .swiper-button-prev {
  top: 50%;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: var(--swiper-next-arrow);
  background-color: var(--swiper-next-bg)
}

.category-wrapper .category-promotion {
  height: 100%;
  padding: 40px 15px 30px 15px;
  text-align: center;
  border-radius: 10px;
}
.category-wrapper a {
  text-decoration: none;
}
.category-wrapper .category-promotion h4 {
  font-size: 22px;
  font-weight: 900;
  line-height: 33px;
  font-family: "Roboto";
  margin-bottom: 10px;
  color: #636363;
}
.category-wrapper .category-promotion h3 {
  font-size: 26px;
  font-weight: 900;
  line-height: 33px;
  font-family: "Roboto";
  margin-bottom: 18px;
  color: #636363;
}
.category-wrapper .category-promotion .button-outline {
  font-size: 20px;
  padding: 4px 20px;
  margin-top: 5rem;  font-weight: 400;
  background: transparent;
  display: inline-block;
  border-radius: 5px;
}
.category-wrapper .category-promotion .button-outline:hover {
  color: #777777;
  text-decoration: none;
}
.category-wrapper .category-image-slider .swiper-slide {
  overflow: hidden;
  border: none;
  height: auto;
}
.category-wrapper .category-image-slider .swiper-slide a {
  display: block;
  text-align: center;
}
.category-wrapper .category-image-slider .swiper-slide a .thumb-image {
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 100%;
  height: 132px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 5px;
}
.category-wrapper .category-image-slider .swiper-slide a .thumb-image img {
  width: 100%;
  height: auto;
}
.category-wrapper .category-image-slider .swiper-slide a p {
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 33px;
}
.category-wrapper .category-image-slider .swiper-slide:hover img {
  transform: scale(1.1);
  transition: all 0.2s;
}
.category-wrapper .category-image-slider .swiper-pagination {
  margin: 45px 0 0 0;
  bottom: 0 !important;
}
.category-wrapper .category-image-slider .swiper-button-next,
.category-wrapper .category-image-slider .swiper-button-prev {
  top: calc(50% - 30px);
}
.category-wrapper .category-image-slider:first-child {
  padding-left: 0;
}
.category-wrapper .category-image-slider:last-child {
  padding-right: 0;
}
.category-wrapper .category-list {
  padding: 25px 20px 25px 25px;
}
.category-wrapper .category-slider .swiper-slide .slider-content h3 {
  font-size: 25px;
  line-height: 25px;
}
.category-wrapper
  .category-slider
  .swiper-slide
  .slider-content
  .button-outline {
  font-size: 20px;
  padding: 4px 24px;
  font-weight: 500;
  color: #000;
//   font-family: 'Work Sans', sans-serif;
}
.category-wrapper .category-image-slider .swiper-slide a .thumb-image {
  height: 140px;
}
.category-wrapper .category-image-slider .swiper-button-next,
.category-wrapper .category-image-slider .swiper-button-prev {
  top: calc(50% - 60px);
}

/* Dropdown menu style */
.categories {
  max-width: 350px;
  position: relative;
  z-index: 99;
  background-color: #ffffff;
  cursor: pointer;
}
.categories .dropdown-button {
  order: none;
  background: transparent;
  width: 100%;
  padding: 20px 10px;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
}
.categories .dropdown-button:before {
  content: "";
  position: absolute;
  display: none;
  bottom: -2px;
  left: 30px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #d9e8fb;
  z-index: 1;
}
.categories .dropdown-button i {
  margin-right: 13px;
  font-size: 18px;
}
.categories .dropdown-button .category-list {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  background: #ffffff;
  display: none;
  position: absolute;
  left: 0;
  width: 320px;
  top: 100%;
}
.categories .dropdown-button .category-list > ul {
  position: relative;
  padding: 0 !important;
}
.categories .dropdown-button .category-list > ul > ul {
  /* position: relative; */
  padding-left: 0 !important;
}
.categories .dropdown-button .category-list > ul > li {
  position: relative;
  padding: 0 35px;
}
.categories .dropdown-button .category-list > ul > li a {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  padding: 13px 0;
  display: block;
  text-transform: capitalize;
  border-bottom: 1px solid #d7d7d7;
  .icon_size {
    margin-right: 10px;
  }
}
.categories .dropdown-button .category-list > ul > li a i {
  margin-right: 12px;
}
.categories .dropdown-button .category-list > ul > li.see-all {
  background-color: #d9e8fb;
  text-align: center;
  padding: 0;
}
.categories .dropdown-button .category-list > ul > li > ul {
  display: none;
  transition: all 0.3s linear;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  min-width: 200px;
}
.categories .dropdown-button .category-list > ul > li > ul > li {
  padding: 0 35px;
}
.categories .dropdown-button .category-list > ul > li > ul > li a {
  border-bottom: 1px solid #d7d7d7;
}
.categories .dropdown-button .category-list > ul > li > ul > li:hover {
  background-color: #d9e8fb;
}
.categories .dropdown-button .category-list > ul > li > ul > li:hover a {
  border-bottom: 1px solid transparent;
}
.categories .dropdown-button .category-list > ul > li > ul > li:last-child a {
  border-bottom: 1px solid transparent;
}
.categories .dropdown-button .category-list > ul > li:hover a i {
  margin-right: 14px;
  transition: all 0.3s linear;
}
.categories .dropdown-button .category-list > ul > li:hover > ul {
  display: block;
}
.categories .dropdown-button .category-list > ul > li:last-child a {
  border: 1px solid transparent;
}
.categories .dropdown-button:hover:before,
.categories .dropdown-button:hover .category-list {
  display: block;
}
.category-list {
  ul {
    li {
      a {
        .icon_size {
          margin-right: 10px;
        }
      }
    }
  }
}
/* ./Dropdown menu style */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-wrapper .category-list {
    padding: 25px 20px 25px 25px;
  }
  .category-wrapper .category-list ul li a {
    font-size: 13px;
  }
  .category-wrapper .category-promotion {
    padding: 15px 10px 15px 10px;
  }
  .category-wrapper .category-promotion h4 {
    font-size: 18px;
    line-height: 20px;
  }
  .category-wrapper .category-promotion h4 {
    font-size: 22px;
    line-height: 25px;
  }
  .category-wrapper .category-slider .swiper-slide .slider-content h3 {
    font-size: 25px;
    line-height: 25px;
  }
  .category-wrapper
    .category-slider
    .swiper-slide
    .slider-content
    .button-outline {
    font-size: 14px;
    padding: 3px 12px;
  }
  .category-wrapper .category-image-slider .swiper-slide a .thumb-image {
    height: 90px;
  }
}

@media only screen and (min-width: 768px) {
  .mini-slide img {
    width: 70%;
    /* height: 70px; */
  }
}

.catsldider {
 padding-left: 24px;
 padding-right: 24px;
}