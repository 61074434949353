.pagination {
    padding: 30px 0 0 0;
    align-items: center;
    @media (max-width: 767px) {
        justify-content: center;
        flex-wrap: wrap;
    }
    li {
        margin-bottom: 10px;
        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #fff;
            text-decoration: none;
        }
    }
    .previous {
        a {
            color: #17181B;
        }
        margin-right: 50px;
        @include media-break-width(320, 767) {
            margin-right: 30px;
        }
    }
    .next {
        a {
            color: #17181B;
        }
       margin-left: 50px;
       @include media-break-width(320, 767) {
        margin-left: 30px;
    }
    }
    .page__count {
        a {
            height: 35px;
            width: 35px;
            border-radius: 50%;
            line-height: 35px;
            text-align: center;
            background: var(--bg_color);
            display: inline-block;
			border: 1px solid;
            &.active {
                background-color: var(--active_bg_color);
            }
        }
    }
    .page__count {
        margin-right: 15px;
        cursor: pointer;
        &.selected {
            background: #17181B;
            border-radius: 20px;
            height: 35px;
            width: 35px;
            display: grid;
            place-content: center;
            a {
                color: #fff;
            }
            @include media-break-width(320, 767) {
                width: 30px;
                height: 30px;
            }
        }
    }
    .break {
        margin-right: 15px;
    }
}