.single-product-wrap .product-gallery-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.single-product-wrap .radio-wrap .radio-item:hover label {
    background: #2e74e8;
    border-color: #2e74e8;
    outline-style: none;
    color: #fff;
}
.single-product-wrap .radio-wrap .radio-item:hover label.variation-active {
    color: #fff !important;
}
.single-product-wrap .product-gallery-container .gallery-main {
  width: calc(100% - 125px);
  height: 100%;
}
.single-product-wrap
  .product-gallery-container
  .gallery-main
  .swiper-wrapper
  .swiper-slide {
  border-radius: 8px;
  padding: 40px 50px;
  overflow: hidden;
  background-color: #f3f3f3;
}
.single-product-wrap
  .product-gallery-container
  .gallery-main
  .swiper-wrapper
  .swiper-slide
  img {
  width: 100%;
  height: auto;
}
.single-product-wrap
  .product-gallery-container
  .gallery-main
  .swiper-button-prev,
.single-product-wrap
  .product-gallery-container
  .gallery-main
  .swiper-button-next {
  position: absolute;
  bottom: 0;
  right: 0;
  top: auto;
  left: auto;
  height: 40px;
  width: 40px;

  color: #ffffff;
  background: #777777;
}
.single-product-wrap
  .product-gallery-container
  .gallery-main
  .swiper-button-prev::after,
.single-product-wrap
  .product-gallery-container
  .gallery-main
  .swiper-button-next::after {
  font-size: 12px;
}
.single-product-wrap
  .product-gallery-container
  .gallery-main
  .swiper-button-prev {
  right: 45px;
  border-right: 1px solid #e9e9e9;
}
.single-product-wrap .product-gallery-container .gallery-thumbs {
  order: -1;
  /* margin-top: 20px; */
  width: 20%;

  overflow: hidden;
}
.single-product-wrap
  .product-gallery-container
  .gallery-thumbs
  .swiper-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  // overflow-y: scroll !important;
}

.product-gallery-container .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.swiper {
  /* width: 100%; */
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.single-product-wrap
  .product-gallery-container
  .gallery-thumbs
  .swiper-wrapper
  .swiper-slide {
  padding: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  width: 107px !important;
  background-color: #f3f3f3;
  border-radius: 8px;
}
.single-product-wrap
  .product-gallery-container
  .gallery-thumbs
  .swiper-wrapper
  .swiper-slide
  img {
  transition: 0.3s;
  width: 100%;
  height: auto;
  border-radius: 3px;
}

.single-product-wrap .product-details-container .product-meta.radio-wrap .radio-item 
label.variation-active{
	opacity: 1;
	color: #2e74e8;
	padding: 0px 5px;
    border-radius: 3px;
    line-height: 20px;
}

.single-product-wrap
  .product-gallery-container
  .gallery-thumbs
  .swiper-wrapper
  .swiper-slide-thumb-active{
  opacity: 1;
  border: 1px solid #2e74e8;
}

.single-product-wrap .product-details-container {
  padding: 0 20px;
}
.single-product-wrap .product-details-container .product-title {
  font-size: 40px;
  font-weight: 500;
  line-height: 32px;
  color: #333333;
  margin-bottom: 15px;
  margin-left: -2px;
}
.single-product-wrap .product-details-container .price {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 15px;
}
.single-product-wrap .product-details-container .price .sale {
  color: #333333;
}
.single-product-wrap .product-details-container .price .del {
  color: #777777;
  text-decoration: line-through;
}
.single-product-wrap .product-details-container .ratings {
  margin-bottom: 15px;
}
.single-product-wrap .product-details-container .ratings span {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #777777;
}
.single-product-wrap .product-details-container .excerpt {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  margin: 15px 0;
}
.single-product-wrap .product-details-container .product-meta {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  margin: 15px 0;
  span:not(.label) {
    color: #777;
  }
  flex-wrap: wrap;
  gap: 15px;
}
.product-meta .search-button {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px !important;
}
.single-product-wrap .product-details-container .product-meta.radio-wrap {
  margin-right: 50px;
}
.single-product-wrap
  .product-details-container
  .product-meta.radio-wrap
  .radio-item {
  column-gap: 10px;
}
.single-product-wrap
  .product-details-container
  .product-meta.radio-wrap
  .radio-item
  label {
  padding: 0px 5px;
  border-radius: 3px;
  line-height: 20px;
}
.single-product-wrap
  .product-details-container
  .product-meta.radio-wrap.color-wrap
  .radio-item
  label {
  padding: 8px 8px;
  border-radius: 3px;
}
.single-product-wrap .product-details-container .product-meta .content {
  color: #777777;
}
.product-meta .product-button
{
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 22px;
  color: #ffffff;
  padding: 9px 22px;
}
.product-meta .product-button
{
  background-color: var(--backgroundColor) ;
  border-color: var(--backgroundColor) ;
}
.product-meta .product-button:hover
{
  background-color: var(--backgroundHover) ;
  border-color: var(--backgroundHover) ;
}
.single-product-wrap .product-details-container .cart-buttons-wrap {
  margin-bottom: 15px;
  display: flex;
}

.single-product-wrap .product-details-container .cart-buttons-wrap button {
  margin: 10px 20px 10px 0;
  padding: 6px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-product-wrap {
  .product-details-container {
    .wishlist-compare-wrap {
      margin-bottom: 15px;
      button {
        text-decoration: none;
        font-weight: 500 !important;
        padding: 6px 14px 6px 0;
        color: var(--color);
      }
      p {
        display: inline;
        margin-right: 30px;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: #333333;
      }
      a {
        color: #777777;
        margin-right: 15px;
        display: inline-block;
        font-size: 14px;
        &:hover {
          color: #333333;
        }
      }
    }
    .social-share-wrap {
      display: flex;
      flex-wrap: wrap;
      p {
        margin-right: 15px;
      }
      &:not(:last-child) {
        margin-right: 15px;
      }
      a {
        margin-right: 15px;
        color: #777;
        transition: all ease 0.4s;
        &:hover {
          color: #2e74e8;
        }
      }
    } 
  }
}
 
.single-product-wrap {
  .product-details-container {
    .quantity {
      display: flex;
      align-items: center;
      width: 88px;
      justify-content: space-between;
      border: 1px solid #777;
      border-radius: 4px;
      margin: 20px 0;
      button {
        border: none;
        background-color: transparent;
        height: 28px;
        width: 28px;
      }
      span {
        width: 28px;
        height: 28px;
        border-left: 1px solid #777;
        border-right: 1px solid #777;
        text-align: center;
        line-height: 28px;
      }
    }
  }
}

.review-check {
  margin-bottom: 20px;
  input {
    width: 20px;
  }
}
.from-group {
  margin-bottom: 20px;
}

.products-wrap .single-product.style-1 {
  border-radius: 10px;
  box-shadow: 0px 0px 0px 1px #e1e1e1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.single-product {
  /* background-color: red; */
  height: 380px !important;
}
.products-wrap .style-1 .image-wrap {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 220px !important;
  overflow: hidden;
}
.products-wrap .single-product.style-1 .image-wrap img {
  width: 100%;
  /* background-size: cover; */
  /* height: 200px; */
}
.products-wrap .single-product.style-1 .image-wrap .badges {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.products-wrap .single-product.style-1 .image-wrap .badges .badge {
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 1px;
  height: auto;
  width: auto;
}
.products-wrap .single-product.style-1 .image-wrap .badges .sale-badge {
  background: #2e74e8;
  color: #ffffff;
  order: 1;
}
.products-wrap .single-product.style-1 .image-wrap .badges .tag-badge {
  background: #2e74e8;
  color: #ffffff;
  order: 2;
}
.products-wrap .single-product.style-1 .image-wrap .buttons-wrap {
  position: absolute;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
}
.products-wrap .single-product.style-1 .image-wrap .buttons-wrap button {
  padding: 9px 10px 10px 11px;
  border-radius: 4px;
  background: #0c6adf;
  border: none;
  color: #ffffff;
  margin-right: 5px;
}
.products-wrap
  .single-product.style-1
  .image-wrap
  .buttons-wrap
  button:last-child {
  margin-right: 0;
}
.products-wrap .single-product.style-1 .content-wrap {
  flex: 1;
  padding: 12px 5px 16px 5px;
  text-align: center;
  /* background-color: red; */
}
.products-wrap .single-product.style-1 .content-wrap .variations .variation {
  grid-gap: 10px;
  margin-bottom: 10px;
}
.products-wrap
  .single-product.style-1
  .content-wrap
  .variations
  .variation
  span {
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.products-wrap
  .single-product.style-1
  .content-wrap
  .variations
  .variation
  span.variation-name {
  border: 1px solid #0c6adf;
  color: #0c6adf;
}
.products-wrap .single-product.style-1 .content-wrap .product-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #0c6adf;
  margin-bottom: 5px;
}
.products-wrap .single-product.style-1 .content-wrap .product-title a {
  text-decoration: none;
  color: #0c6adf;
}
.products-wrap .single-product.style-1 .content-wrap .price {
  color: #333333;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 5px;
}
.products-wrap .single-product.style-1 .content-wrap .price .del {
  text-decoration: line-through;
  color: #acacac;
}

.products-wrap .single-product.style-1:hover {
  box-shadow: 0px 2px 5px 2px #e1e1e1;
}
.products-wrap .single-product.style-1:hover .image-wrap {
  background: #f3f3f3;
  transition: all 0.5s linear;
}
.products-wrap .single-product.style-1:hover .image-wrap .buttons-wrap {
  visibility: visible;
  opacity: 1;
  bottom: 20px;
}

@media (max-width: 768px) {
  /* Single product page */
  .single-product-wrap .product-gallery-container {
    position: relative;
    width: 100%;
    // height: 60vh;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  .gallery-main {
    height: 70% !important;
  }
  .single-product-wrap .product-gallery-container .gallery-thumbs {
    order: -1;
    margin-top: 20px;
    width: 100%;

    overflow: hidden;
  }

  .single-product-wrap .product-gallery-container .gallery-thumbs img {
    width: 100% !important;
  }
  .single-product-wrap
    .product-gallery-container
    .gallery-thumbs
    .swiper-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 5px;
    // overflow-y: scroll !important;
  }
	.quantity {
		border: 1px solid rgba(128, 128, 128, 0.156);
		display: inline;
		border-radius: 3px;
	}
  .single-product-wrap .product-gallery-container {
    flex-direction: column;
  }
  .single-product-wrap .product-gallery-container .gallery-main {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .single-product-wrap
    .product-gallery-container
    .gallery-main
    .swiper-wrapper
    .swiper-slide {
    padding: 30px;
  }
  .single-product-wrap .product-gallery-container .gallery-thumbs {
    order: unset;
    max-height: 300px;
  }
  .single-product-wrap
    .product-gallery-container
    .gallery-thumbs
    .swiper-wrapper {
    height: 100%;
    overflow: unset;
  }
  .single-product-wrap
    .product-gallery-container
    .gallery-thumbs
    .swiper-wrapper
    .swiper-slide {
    /* padding: 12px 15px; */
    height: 100% !important;
  }
  .single-product-wrap
    .product-gallery-container
    .gallery-thumbs
    .swiper-wrapper
    .swiper-slide {
    padding: 3px;

    border-radius: 8px;
  }
  .single-product-wrap
    .product-gallery-container
    .gallery-thumbs
    .swiper-wrapper
    .swiper-slide
    img {
    transition: 0.3s;
    width: 50%;
    height: auto;
    border-radius: 3px;
  }
  .single-product-wrap .product-details-container .product-title {
    line-height: 40px;
  }
  /* ./Single product page*/
}
