.sotresContainerList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.sotresContainerList .image {
  display: flex;
  align-items: center;
}
.sotresContainerList img {
  width: 70%;
  border-radius: 50%;
  background-color: #fff;
}

.sotresContainerList .card {
  border: 1px solid rgba(128, 128, 128, 0.279);
  border-radius: 5px;
  background-color: #f7f7f7;
  display: grid;
  grid-template-columns: 4fr 9fr;
  transition: all 0.2s;
}
.sotresContainerList .cardBody {
  padding-top: 0.8rem;
  padding-bottom: 0.1rem;
  display: grid;
  grid-template-columns: 6fr 4fr;
}
.sotresContainerList .visitBtn {
  padding-left: 2rem;
  padding-top: 2rem;
}
.sotresContainerList .card {
  padding: 10px;
}
.sotresContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.sotresContainer .image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.sotresContainer .image img {
  margin-top: 1rem;
  width: 100%;
  border-radius: 50%;
  background-color: #fff;
  display: block;
}

.sotresContainer .card {
  border: 1px solid rgba(128, 128, 128, 0.279);
  border-radius: 5px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  text-align: center;
}

.card:hover {
  box-shadow: 1px 3px 7px rgba(180, 180, 180, 0.681);
  /* border: none; */
  cursor: pointer;
}

.sotresContainer .cardBody {
  width: 100%;
  padding-left: 1rem;
}

@media (max-width: 768px) {
  .sotresContainer .cardBody {
    padding-right: 1rem;
  }
}

.cardBody h5 {
  margin-top: 1rem;
  font-weight: 500;
  line-height: 5px;
  margin-bottom: 15px;
  color: #212529;
  line-height: 1.3;
}

.rattings {
  font-size: 0.8rem;
  color: gray;
  justify-content: center;
}
.location {
  justify-content: center;
}
.location span {
  margin-right: 8px;
}

.phone span {
  margin-right: 8px;
}

.email span {
  margin-right: 8px;
}

.sortBar {
  display: grid;
  grid-template-columns: 5fr 5fr;
  border: 1px solid rgba(128, 128, 128, 0.279);
  border-radius: 5px;
  background-color: #f8f9fa;
  padding: 10px 10px;
}
.sortInput {
  border: 1px solid rgba(128, 128, 128, 0.279);
  border-radius: 5px;
  background-color: #f8f9fa;
  padding: 0;
  height: 0;
  width: 0;
  opacity: 0;
  transition: all 0.3s;
  z-index: -1;
  /* display: none; */
}
.sortInput button {
  background-color: transparent !important;
}

.sortInputShow {
  border: 1px solid rgba(128, 128, 128, 0.279);
  border-radius: 5px;
  background-color: #f8f9fa;
  padding: 20px 30px;
  height: 150px;
  width: 100%;
  opacity: 1;
  /* display: block; */
  transition: all 0.3s;
}

.sortBarLeft {
  /* background-color: red; */
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 1rem;
}
.sortBarLeft h6 {
  font-size: 1.2rem;
  color: rgb(86, 86, 86);
}
.sortBarRight {
  display: flex;
  justify-content: end;
}

.filterBtn {
  padding: 3px 15px 3px 10px;
  /* background-color: blue; */
  color: #fff;
  border: none;
  border-radius: 3px;
}

.layoutBtn {
  margin-left: 1rem;
  font-size: 1rem;
  display: none !important;
}
@media only screen and (min-width: 768px) {
  .sotresContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sotresContainerList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .sotresContainerList .location {
    justify-content: flex-start;
  }
  .sotresContainerList .rattings {
    justify-content: flex-start;
  }
  .sortBar {
    display: grid;
    grid-template-columns: 10fr 2fr;
    border: 1px solid rgba(128, 128, 128, 0.279);
    border-radius: 5px;
    background-color: #f8f9fa;
    padding: 10px 10px;
  }

  .layoutBtn {
    margin-left: 1rem;
    font-size: 1rem;
    display: block !important;
  }
}

