/* Global CSS */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

body {
	font-family: 'Inter', sans-serif;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

li {
	display: inline-block;
}

a {
	text-decoration: none;
}

img {
	max-width: 100%;
	width: 100%;
}

/* Single Product CSS */
.single-product-area .col-lg-4.col-sm-12 {
	padding: 0;
}

.single-product-block {
	padding: 10px;
	background-color: #ffffff;
	border: 1px solid #ececec;
	transition: box-shadow 0.3s ease-in-out;
}

.single-product-block:hover {
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.12);
}

.single-product-block .product-thumbnail {
	background-color: #f8f8f8;
	position: relative;
	height: 235px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px 6px 0px 0px;
	margin-bottom: 25px;
}

.single-product-block .product-content {
	padding: 0px 14px 16px;
	position: relative;
	height: 155px;
}

.single-product-block .product-content .product-title {
	line-height: 1.2em;
	margin-bottom: 18px;
	height: 45px;
    overflow: hidden;
}

.single-product-block .product-content .product-title a {
	font-size: 18px;
	line-height: 22px;
	font-weight: 700;
	display: inline-block;
	color: #092636;
}

.single-product-block .product-content .product-cat a {
	color: #666;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	display: inline-block;
	margin-bottom: 5px;
}

.single-product-block .product-content .price-and-rating {
	display: flex;
	justify-content: space-between;
	align-items: end;
	transition: all 0.5s ease-in-out;
	padding-top: 20px;
}

.single-product-block:hover .product-content .price-and-rating {
	opacity: 0;
}

.single-product-block .product-content .price-and-rating .rating li {
	color: #ffc601;
	margin: 0px 1px;
}

.single-product-block .product-content .price-and-rating .price .offer-price {
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;
	color: #f84d17;
	margin: 0;
}

.single-product-block .product-content .price-and-rating .price .main-price del {
	color: #999;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
}

.single-product-block .product-content .price-and-rating .price {
	position: relative;
}

.single-product-block {

	.product-content {

		.price-and-rating {

			.price {

				.main-price {

					position: absolute;
					top: -20px;
					font-size: 16px;
					font-weight: 500;
					color: #F84D17;
				}
			}
		}
	}
}

.single-product-block .sale-and-wishlist {
	display: flex;
	justify-content: space-between;
	align-self: start;
	position: absolute;
	top: 0;
	width: 100%;
	padding: 10px;
}

.single-product-block .sale-and-wishlist .offer-badge {
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 17px;
	padding: 2px 7px;
	color: #ffffff;
	border-radius: 4px;
	height: 22px;
}

.single-product-block .sale-and-wishlist .sale {
	background-color: #4caf50;
}

.single-product-block .sale-and-wishlist .new {
	background-color: #f87c21;
}

.single-product-block .sale-and-wishlist .off {
	background-color: #5019a8;
}

.single-product-block .sale-and-wishlist .wishlist a {
	background-color: #ffffff;
	color: #5c4e4e;
	display: inline-block;
	height: 30px;
	width: 30px;
	border-radius: 30px;
	text-align: center;
	line-height: 30px;
	font-size: 14px;
}

.single-product-block .product-content .hover-item {
	opacity: 0;
	transition: all 0.5s ease-in-out;
	position: absolute;
	bottom: 0;
    top: 100px;
}

.single-product-block:hover .product-content .hover-item {
	opacity: 1;
	padding-left: 0;
}

.single-product-block .product-content .hover-item .quick-display a,
.single-product-block .product-content .hover-item .wishlist a,
.single-product-block .product-content .hover-item .comparison a {
	color: #092636;
	display: inline-block;
	height: 36px;
	width: 36px;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	border-radius: 6px;
	text-align: center;
	padding-top: 3px;
	font-size: 17px;
}

.single-product-block .product-content .hover-item li {
	margin-right: 6px;
	cursor: pointer;
}

.single-product-block .product-content .hover-item li:last-child {
	margin-right: 0px;
}

.single-product-block .product-content .hover-item .cart-button a {
	display: inline-block;
	background: #f84d17;
	color: #fff;
	padding: 6px 18px;
	border-radius: 6px;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 23px;
	font-weight: 600;
}

.daily-deals .single-product-block .product-content {
	padding: 0px 10px 16px;
}

.daily-deals .single-product-block .product-content .hover-item .cart-button a.add-to-cart {
    padding: 6px 12px;
}

.view-all a {
	color: #092636;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
	padding-right: 5px;
	transition: width 0.5s;
}

.view-all a::after {
	content: "";
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: #092636;
	transition: width 0.3s;
}

.view-all a:hover::after {
	width: 0%;
}

.view-all i {
	font-size: 20px;
	position: relative;
	top: 2px;
}

.shop-btn {
	display: inline-block;
	padding: 18px 35px;
	background: #f84d17;
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
	line-height: 24px;
	font-weight: 600;
	transition: all 0.5s ease-in-out;
	border-radius: 6px;

	&:hover {
		background: #fff;
		color: #f84d17;
	}

	i {
		margin-left: 10px;
		font-size: 20px;
	}
}

.col-padding {
	padding: 0 !important;
}
.wishlist{
	cursor: pointer;
}
// Swiper Slider CSS
.category-slider .swiper .swiper-button-next,
.category-slider .swiper .swiper-button-prev {
	height: 36px;
	width: 36px;
	background-color: #f84d17;
	color: #fff;
	border-radius: 50px;

	&::after {
		font-size: 14px;
		font-weight: 700;
	}
}

.swiper-button-next {
	right: 0 !important;
}