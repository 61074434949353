/* .productTable thead {
  background-color: #d9e8fb;
} */
.del{
	color: #777777;
	text-decoration: line-through;
}
.productTable tr {
  border-bottom: 1px solid rgba(128, 128, 128, 0.176);
}

.productTable table tbody tr {
  padding-left: 50px;
}
.quantity {
  border: 1px solid rgba(128, 128, 128, 0.156);
  display: inline-block;
  border-radius: 3px;
}

.quantity button {
  border: none;
  background-color: inherit;
  padding: 0px 10px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2;
}
.quantity span {
  padding: 0 15px;
}

@media (max-width: 992px) {
  .quantity button {
    padding: 0px 3px;
  }
  .quantity span {
    padding: 0;
  }
}
.deleteBtn {
  background-color: inherit;
  border: none;
  font-size: 1.2rem;
}

.actionButton button {
  color: rgba(0, 0, 0, 0.78);
  margin-right: 1rem;
  border: 1px solid gray;
}

.coupon h5 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1rem !important;
}

.coupon input {
  border-radius: 8px 0 0 8px;
  padding-right: 100px;
}
.couponBtn {
  padding: 10px 15px !important;
  border-radius: 0 8px 8px 0 !important;
  background-color: #2e74e8 !important;
  color: #fff !important;
}

.couponBtn:hover {
  color: #fff;
}

.cartTotalsRow span {
  display: block;
  font-weight: 500;
}

.cart_totals {
  background-color: #f8f9fa;
  padding: 1rem;
}

.cartTotalsRow {
  display: grid;
  grid-template-columns: 7fr 3fr;
  border-bottom: 1px solid rgba(128, 128, 128, 0.249);
  padding: 15px 0;
}
.cartTotalsRowLast {
  display: grid;
  grid-template-columns: 7fr 3fr;
  border-bottom: none !important;
  padding: 15px 0;
}
.cartTotalsRowLast small {
  font-size: 0.8rem;
}

.cart_totals h5 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1rem !important;
}

.proceedBtn button {
  width: 100%;
  padding: 12px;
  background-color: #2e74e8;
  border: none;
  color: #fff;
  border-radius: 6px;
}
.cfrom {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .cfrom {
    width: 400px;
    border-radius: 8px 0 0 8px;
  }
  .couponBtn {
    padding: 10px 15px !important;
    border: none;
    border-radius: 0 8px 8px 0 !important;
    background-color: #2e74e8 !important;
    color: #fff !important;
  }
  .cartTotalsRow span {
    font-size: 15px;
  }
}
.couponBtn {
  position: absolute;
  right: -4px;
}
@media (max-width: 768px) {
  .cfrom {
    position: relative;
    width: 100%;
  }
  .coupon input {
    width: 100%;
    padding-right: 100px;
    width: 100%;
  }
  .couponBtn {
    position: absolute;
    right: -4px;
  }
}

.cuponfrom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.errormsg {
  order: 12;
  flex: 0 0 100%;
  margin: 10px 0;
}
@media (max-width: 767px) {
  .carttable {
    width: 600px !important;
  }
}