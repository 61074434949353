.main_header_section {
    display: flex;
    align-items: center;
}

.topbar,
.main-header {
	border-style: solid;
	border-width: 0px 0px 1px 0px;
	border-color: #D8E1E6;
	padding: 3px 25px 3px 25px;
	overflow: visible;

	@media(max-width: 767px) {
		padding: 3px 15px 3px 15px;
	}
}

.main-header {
	padding-top: 20px;
	padding-bottom: 20px;
}

.list-inline,
.top-left-list {
	display: block;
	font-size: 13px;
	line-height: 1em;
	margin: 0 auto;
	padding: 15px 0;
}

.list-inline {
	li {
		padding: 2px 15px;
		border-style: solid;
		border: none;
		border-right: 1px solid #D4DADD;
		display: inline-block;

		&:last-child {
			border-right: none;
		}

		a {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			color: #092636;
		}
	}
}

.right-1-menu-area {
    top: 10px;
    position: relative;
}

.top-left-list li svg {
    font-size: 6px;
    position: relative;
    top: -1px;
}