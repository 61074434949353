.background {
  background-color: white;
  margin-top: 12px;
  margin-left: 0 !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: 5px;
  border-radius: 5px;
  padding-bottom: 30px;
}

.infoContainer {
  display: grid;
  grid-template-columns: 1fr 10fr;

  grid-row-gap: 1rem;
  grid-column-gap: 0.5rem;
}
.background h3 {
  font-size: 1.2rem;
}
.balance {
  display: flex;
  justify-content: space-between;
  padding-left: 1.7rem;
  margin-top: -1rem;
  margin-bottom: 4rem;
  padding-right: 4rem;
  background-color: #85ffbd;
  background-image: linear-gradient(45deg, #85ffbd 0%, #fffb7d 100%);
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-direction: column;
}

.balance h4,
button {
  font-weight: 600 !important;
}
.tableData small {
  font-size: 0.9rem;
}

.tableData thead th {
  border-bottom: 1px solid rgba(215, 215, 215, 0.432);
  padding-bottom: 20px !important;
}
.tableData table td {
  padding-top: 25px !important;
}
hr {
  opacity: 0.1;
}

.hide {
  display: none;
}

.header p {
  font-size: 0.9rem;
  font-weight: 600;
}

.code {
  color: #1163e7;
  cursor: pointer;
}

.unpaid {
  background-color: #ef486a;
  color: #fff;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 0.9rem;
  margin-right: 0.2rem;
}

.paid {
  padding: 2px 4px;
  border-radius: 3px;
  background-color: #1163e7;
  color: #fff;
  font-size: 0.9rem;
  margin-right: 0.2rem;
}

@media only screen and (min-width: 768px) {
  .infoContainer {
    display: grid;
    grid-template-columns: 1fr 10fr;
    grid-gap: 1rem;
  }
  .background {
    background-color: white;
    margin-top: 12px !important;
    margin-left: 10px;
    border-radius: 5px;
  }
  .balance {
    display: flex;
    justify-content: space-between;
    padding-left: 1.7rem;
    margin-top: -1rem;
    margin-bottom: 4rem;
    padding-right: 4rem;
    background-color: #85ffbd;
    background-image: linear-gradient(45deg, #85ffbd 0%, #fffb7d 100%);
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-direction: row;
  }

  .background {
    background-color: white;
    margin-top: 12px;
    margin-left: 1rem !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 5px;
    border-radius: 5px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1024px) {
  .hide {
    display: block;
  }

  .infoContainer {
    display: grid;
    grid-template-columns: 200px auto;
    grid-gap: 1rem;
  }
}
