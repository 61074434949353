.activeLink {
  background-color: var(--bg_color);
  border-bottom: 1px solid var(--bg_color) !important;
}

.linksContainer {
  background-color: #f8f9fa;
}

.linksContainer ul {
  padding: 0;
}

.linksContainer ul li a {
  text-decoration: none;
  padding-left: 2rem;
  color: rgba(0, 0, 0, 0.899);
  font-size: 1rem;
  display: block;
  padding: 12px 25px;
}

li.activeLink a {
  color: var(--color) !important;
}
.linksContainer ul li {
  padding-bottom: 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.089);
}
