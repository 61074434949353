.hero-area-wrapper {
	padding: 80px 30px;
	background: var(--slider1-bg);
	margin-bottom: 50px;
}

@media (min-width: 1025px) {
	.hero-area-wrapper {
		padding: 100px 100px;
	}
}

.hero-content-area .title {
	color: var(--slider1-first_line);
	font-size: 56px;
	line-height: 60px;
	font-weight: 800;
	margin-bottom: 30px;
}

.hero-content-area .hero-subtitle {
	font-size: 18px;
	line-height: 20px;
	color: var(--slider1-title);
	text-transform: uppercase;
}

.hero-content-area .description {
	font-size: 18px;
	line-height: 21px;
	color: var(--slider1-second_line);
	margin-bottom: 40px;
}

.hero-image-area img {
	width: 100%;
}

@media (min-width: 1024px) {
	.hero-content-area .title {
		font-size: 46px;
		line-height: 56px;
	}
}

@media (max-width: 767px) {
	.hero-area-wrapper {
		padding: 60px 30px;
	}

	.hero-content-area .title {
		font-size: 36px;
		line-height: 46px;
	}

	.hero-content-area {
		padding-bottom: 45px;
	}
}

.slider {
	height: 532px !important;
}

.nextButton,
.previousButton {
	display: none !important;
}
