/* Grid view css start*/
.prods-grid-view-container {
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  gap: 50px 20px;
}
.vartical-prod-card-container {
  background: #fff;
  text-align: center;
  transition: all ease 0.4s;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.22));
    border-color: #d7d7d7;
    .vpcc-image {
      background-color: #fff;
      border-radius: 10px;
    }
  }
}
.vpcc-image {
  height: 228px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.vpcc-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  transition: 0.3s;
  font-size: 115%;
  line-height: 130%;
  margin: 10px 0 5px;
  font-weight: bold;
  color: #0c6adf;
  padding: 0 10px;
}
.vpcc-name:hover {
  color: #2d73e7;
  cursor: pointer;
}

.vpcc-price {
  font-size: 110%;
  line-height: 1.2;
  min-height: 15px;
  color: #333;
  align-items: baseline;
  flex-wrap: wrap;
  flex: 0 0 100%;
}

.vpcc-image img {
  max-width: 100%;
}
.vpcc-footer {
  text-align: center;
  padding: 0 10px 16px;
}
.card-action-btn-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  transition: 0.5s;
}
.vpcc-btns {
  background-color: #2d73e7;
  height: 37px;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: 0.5s;
}
.vpcc-btns:hover {
  transform: scale(1.2);
}
.vartical-prod-card-container:hover .card-action-btn-container {
  height: 70px;
}
/* Grid view css end*/

/* List view css start */
.prods-list-view-container {
  display: flex;
  flex-direction: column;
}
.horizontal-prod-card-container {
  display: grid;
  grid-template-columns: 200px auto 250px;
  border-top: 1px solid #efefef;
  padding: 25px 20px;
  transition: 0.3s;
}
.horizontal-prod-card-container:hover {
  box-shadow: 0 0 6px 0 rgb(1 1 1 / 10%);
}
.hpcc-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  background-color: #f8f8f8;
}
.hpcc-image img {
  max-width: 100%;
  max-height: 100%;
}
.hpcc-content {
  padding: 0 20px;
}
.hpcc-name {
  font-weight: 500;
  font-size: 18px;
  transition: 0.3s;
  cursor: pointer;
  padding-bottom: 10px;
  line-height: 1.2;
}

.hpcc-name:hover {
  color: #2d73e7;
}
.hpcc-rating {
  margin-bottom: 10px;
}
.hpcc-price {
  padding: 10px 0;
  font-size: 20px;
  font-weight: 300;
}
.hpcc-description {
  border-top: 1px solid #ddd;
  padding: 10px 0;
  margin-top: 15px;
  color: #555;
  font-weight: 300;
}
.hpcc-actions {
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
  padding: 3px 0 5px 50px;
}
.hpcc-btns {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.hpcc-btns:hover {
  color: #2d73e7;
}
/* List view css end */
