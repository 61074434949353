.cuponContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
.cartEmpty{
  font-size: 16px;
  font-style: italic;
}

@media only screen and (min-width: 768px) {
  .cuponContainer {
    display: grid;
    grid-template-columns: 6fr 3fr;
    grid-gap: 15px;
  }
}

@media (max-width: 768px) {
  .carttable {
    width: 500px;
  }
}