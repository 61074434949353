.paginationContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.paginationContainer .paginationSelect {
  margin-bottom: -15px;
  margin-left: -15px;
}

.prevBtn,
.nextBtn {
  background-color: var(--btn_color);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  color: gray !important;
  border: 1px solid;
}
.prevBtn:hover {
  background-color: var(--bg_color);
  color: rgb(79, 79, 79) !important;
}
.nextBtn:hover {
  background-color: var(--bg_color);
  color: rgb(79, 79, 79) !important;
}

/* .activeBtn {
  background-color: var(--bg_color);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

@media only screen and (min-width: 768px) {
  .paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-right: 2rem;
  }

  .paginationContainer .paginationSelect {
    margin-right: 3rem;
    margin-top: 12px;
    margin-bottom: 0;
  }
  .prevBtn {
    margin-right: -50px;
  }
  .nextBtn {
    margin-left: -50px;
  }
}
